import CheckButton from '@components/shared/Inputs/CheckButton';
import React, { useMemo } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';

interface BatchWarehouseSchedulingViewOptionsProps {
  showOrders: boolean;
  showUnits: boolean;
  showDecorations: boolean;
  toggleOrders: () => void;
  toggleUnits: () => void;
  toggleDecorations: () => void;
}

const BatchWarehouseSchedulingViewOptions: React.FC<BatchWarehouseSchedulingViewOptionsProps> = ({
  showOrders,
  showUnits,
  showDecorations,
  toggleOrders,
  toggleUnits,
  toggleDecorations,
}) => {
  const viewExample = useMemo(() => {
    const parts = [];
    if (showOrders) parts.push('X Orders');
    if (showUnits) parts.push('X Units');
    if (showDecorations) parts.push('X Decorations');

    if (parts.length > 0) return parts.join(' / ');

    return 'No data selected';
  }, [
    showOrders,
    showUnits,
    showDecorations,
  ]);

  return (
    <Box display='flex' flexDirection='column' className='view-options'>
      <Typography variant='h6'>View Options</Typography>
      <Box display='flex' flexDirection='row' alignItems='center' className='button-container'>
        <CheckButton
          disabled={false}
          text={'Orders'}
          checked={showOrders}
          onClick={toggleOrders}
        />
        <CheckButton
          disabled={false}
          text={'Units'}
          checked={showUnits}
          onClick={toggleUnits}
        />
        <CheckButton
          disabled={false}
          text={'Decorations'}
          checked={showDecorations}
          onClick={toggleDecorations}
        />
        <Typography variant='body1'><b>{viewExample}</b></Typography>
      </Box>
    </Box>
  );
};

export default BatchWarehouseSchedulingViewOptions;
