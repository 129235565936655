import React from 'react';
import Klarna from '@assets/klarna.svg';
import Afterpay from '@assets/afterpay.svg';
import Affirm from '@assets/affirm.svg';
import AmazonPay from '@assets/amazon-pay.svg';
import LinkPay from '@assets/link-pay.svg';
import Card from '@assets/card.svg';
import { stripePaymentMethodEnum } from '@constants/enums/commonEnums';

interface OrderPaymentMethodProps {
  stripePaymentMethod?: string | null;
}

export const OrderPaymentMethod: React.FC<OrderPaymentMethodProps> = ({ stripePaymentMethod }) => {
  switch (stripePaymentMethod) {
    case stripePaymentMethodEnum.Card:
      return <span className='payment-method'><img src={Card} alt='Credit Card' /> Credit Card</span>;
    case stripePaymentMethodEnum.Affirm:
      return <span className='payment-method'><img src={Affirm} alt='Affirm' /> Affirm</span>;
    case stripePaymentMethodEnum.Afterpay:
      return <span className='payment-method'><img src={Afterpay} alt='Afterpay' /> AfterPay</span>;
    case stripePaymentMethodEnum.Klarna:
      return <span className='payment-method'><img src={Klarna} alt='Klarna' /> Klarna</span>;
    case stripePaymentMethodEnum.AmazonPay:
      return <span className='payment-method'><img src={AmazonPay} alt='Amazon Pay' /> Amazon Pay</span>;
    case stripePaymentMethodEnum.Link:
      return <span className='payment-method'><img src={LinkPay} alt='Link' /> Link</span>;
    default:
      return <span className='payment-method'>{stripePaymentMethod}</span>;
  }
};
