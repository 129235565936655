/*
 * Generated by orval v5.5.3 🍺
 * Do not edit manually.
 * Fulfillment Homefield API
 * OpenAPI spec version: v1
 */

export type SubmitStatusEnum = 'NotScheduled' | 'Pending' | 'PendingDesignFiles' | 'Failed' | 'Done';


export const SubmitStatusEnum = {
  NotScheduled: 'NotScheduled' as SubmitStatusEnum,
  Pending: 'Pending' as SubmitStatusEnum,
  PendingDesignFiles: 'PendingDesignFiles' as SubmitStatusEnum,
  Failed: 'Failed' as SubmitStatusEnum,
  Done: 'Done' as SubmitStatusEnum,
};
