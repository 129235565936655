import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import Table from '@components/shared/Table/Table';
import { OrderItemFileJobDto, UploadItemFileDto } from '@api/fulfillment/models';
import {
  useGetHomefieldApiOrderitemfilesJobs,
  postHomefieldApiOrderitemfilesFiles,
  putHomefieldApiOrderitemfilesJobs,
} from '@api/fulfillment/order-item-files';
import { getPagingParamsFromTable } from '@util/tableHelpers';
import HeaderCell from '@components/shared/Table/TableCells/HeaderCell';
import { parseDateNumeric } from '@util/dateHandler';
import { getSelectableTableRowProps } from '@util/selectionHelpers';
import OrderItemFileJobDetails from './OrderItemFileJobDetails';
import { UploadFileHandlerType } from './OrderItemFilesSharedTypes';
import { postHomefieldApiSupportFilesuploadcloud } from '@api/fulfillment/support';
import Button from '@components/shared/Buttons/Button';
import TableBulkActions from '@sharedComponents/Table/TableComponents/TableBulkActions';
import { convertToCsv, downloadFromLink } from '@util/componentHelper';
import moment from 'moment';

const JobsTable = Table<OrderItemFileJobDto>();

export const OrderItemFiles: React.FC = () => {
  const [viewCompleted, setViewCompleted] = useState(false);
  const [pageConfig, setPageConfig] =
    useState<{ pageNumber: number; pageSize: number; }>({
      pageNumber: 1,
      pageSize: 10,
    });
  const [focusedJob, setFocusedJob] = useState<OrderItemFileJobDto | null>(null);
  const [selectedJobs, setSelectedJobs] = useState<OrderItemFileJobDto[]>([]);

  const { data, refetch, isLoading } = useGetHomefieldApiOrderitemfilesJobs(
    {
      pageNumber: pageConfig.pageNumber || 1,
      pageSize: pageConfig.pageSize || 10,
      isCompleted: viewCompleted,
      jobType: 'MERFRM001', // hard coded for now as we're probably getting rid of this functionality
    }, {
      query: {
        enabled: true,
        refetchOnWindowFocus: false,
      },
    }
  );

  useEffect(() => {
    if (isLoading) return;
    if (!focusedJob?.id) return;
    setFocusedJob((prev) => data?.items?.find((job) => job.id === prev?.id) ?? null);
  }, [isLoading, data?.items, focusedJob?.id]);

  const tableColumn = useCallback(
    <TKey extends keyof OrderItemFileJobDto>(
      name: string, accessor: TKey, cb: (arg: OrderItemFileJobDto[TKey]) => React.ReactNode, sortable?: boolean
    ) => ({
      Header: sortable ? <HeaderCell text={name} /> : name,
      accessor: '',
      id: accessor,
      minWidth: 100,
      sortable,
      Cell: ({ original }: { original: OrderItemFileJobDto; }) => cb(original[accessor]),
    }), []
  );

  const stringTableColumn = useCallback(
    (
      name: string,
      accessor: keyof OrderItemFileJobDto,
      sortable?: boolean
    ) => tableColumn(name, accessor, (arg) => arg?.toString(), sortable)
    , [tableColumn]
  );

  const dateTableColumn = useCallback(
    (
      name: string,
      accessor: 'datePlaced' | 'shipDate',
      sortable?: boolean
    ) => tableColumn(name, accessor, parseDateNumeric, sortable)
    , [tableColumn]
  );

  const columns = useMemo(() => [
    // OMIT stringTableColumn('ID', 'id', false),
    stringTableColumn('Job Type', 'jobType', false),
    stringTableColumn('Order Number', 'orderNumber', false),
    dateTableColumn('Ship Date', 'shipDate', false),
    dateTableColumn('Date Placed', 'datePlaced', false),
    tableColumn('Items', 'jobItems', (arg) => arg?.length ?? 0, false),
  ], [dateTableColumn, stringTableColumn, tableColumn]);

  const handleFetchData = useCallback((_: unknown, instance: unknown) => {
    const {
      page: pageNumber,
      pageSize,
    } = getPagingParamsFromTable(instance);

    setPageConfig({
      pageNumber,
      pageSize,
    });
  }, []);
  const handleFocusJob =
    useCallback((state: unknown, rowInfo: unknown) => getSelectableTableRowProps<OrderItemFileJobDto>(
      (ord) => setFocusedJob(ord),
      rowInfo as { original: OrderItemFileJobDto; },
      focusedJob,
      'id'
    ), [focusedJob]);
  const handleCloseFocusedJob = useCallback(() => setFocusedJob(null), []);
  const handleTabClick = useCallback((completed: boolean) => () => {
    setViewCompleted(completed);
    handleCloseFocusedJob();
  }, [handleCloseFocusedJob]);

  const handleUpload: UploadFileHandlerType = useCallback(async (files) => {
    const uploadedMap = new Map<number, UploadItemFileDto[]>();
    for (const file of files) {
      const res = await postHomefieldApiSupportFilesuploadcloud({
        file: file.file,
      }, {
        isBlockingRequest: true,
        showErrorModal: true,
      });
      if (res.success) {
        if (uploadedMap.has(file.jobId)) {
          uploadedMap.get(file.jobId)!.push({
            fileUrl: res.result!,
            orderItemId: file.orderItemId,
          });
        } else {
          uploadedMap.set(file.jobId, [
            {
              fileUrl: res.result!,
              orderItemId: file.orderItemId,
            },
          ]);
        }
      }
    }

    for (const [jobId, items] of uploadedMap) {
      await postHomefieldApiOrderitemfilesFiles({
        jobId,
        items,
      }, {
        isBlockingRequest: true,
        showErrorModal: true,
      });
    }

    refetch();
  }, [refetch]);

  const handleCompleteToggle = useCallback(async (job: OrderItemFileJobDto) => {
    await putHomefieldApiOrderitemfilesJobs({
      jobId: job.id,
      isCompleted: !job.isCompleted,
    }, {
      isBlockingRequest: true,
      showErrorModal: true,
    });
    handleCloseFocusedJob();
    refetch();
  }, [refetch, handleCloseFocusedJob]);

  const handleSelect = useCallback((
    newSelectedItems: Array<OrderItemFileJobDto>
  ) => {
    setSelectedJobs(newSelectedItems);
  }, []);

  const handleSelectAll = useCallback(() => {
    setSelectedJobs(data?.items ?? []);
  }, [data?.items]);

  const handleClearAll = useCallback(() => {
    setSelectedJobs([]);
  }, []);

  const handleDownloadCsv = useCallback(() => {
    const flattened = selectedJobs.flatMap((job) => job.jobItems!.map((item) => ({
      JobType: job.jobType ?? '',
      OrderNumber: job.orderNumber?.toString() ?? '',
      ItemId: item.orderItemId?.toString() ?? '',
      Sku: item.sku ?? '',
      DesignId: item.designId ?? '',
      Text: item.text ?? '',
      Number: item.number ?? '',
    })));
    const csvContent = convertToCsv(flattened);
    const timestamp = moment().format('YYYY-MM-DD-HH-mm-ss');
    downloadFromLink(csvContent, `OrderItemFiles_${timestamp}.csv`, 'text/csv');
  }, [selectedJobs]);

  const wrappedColumns = useMemo(() => [
    {
      Header: (
        <TableBulkActions
          selectedItems={selectedJobs}
          clearAll={handleClearAll}
          bulkActions={(
            <div className='actions-row__action'>
              <Button
                text='Download CSV'
                type='primary'
                onClick={handleDownloadCsv}
              />
            </div>
          )}
        />
      ),
      columns,
    },
  ], [columns, handleClearAll, handleDownloadCsv, selectedJobs]);

  return (
    <div className='container'>
      <ul className='tabs tabs--size-l'>
        <li
          className={`tab-item ${!viewCompleted ? 'is-active' : ''}`}
          onClick={handleTabClick(false)}
        >
          In Progress
        </li>
        <li
          className={`tab-item ${viewCompleted ? 'is-active' : ''}`}
          onClick={handleTabClick(true)}
        >
          Completed
        </li>
      </ul>
      <div className='master-detail'>
        <div className='lockerManager__master'>
          <div className='sheet'>
            <JobsTable
              data={data?.items ?? []}
              onFetchData={handleFetchData}
              columns={wrappedColumns}

              hasNextPage={data?.hasNextPage}
              hasPreviousPage={data?.hasPreviousPage}
              totalPages={data?.totalPages}
              getTrProps={handleFocusJob}
              pageSizeOptions={[
                10,
                25,
                50,
                100,
                200,
                500,
              ]}

              selectable={true}
              selectedData={selectedJobs}
              onSelectBulkAction={handleSelectAll}
              updateSelection={handleSelect}
              selectPredicateOrKey={'id'}
              isPageSelected={!!data?.items?.length && selectedJobs.length === data?.items?.length}
            // TODO isSelectingItemDisabled={isSelectingItemDisabled}
            />
          </div>
        </div>
        {focusedJob &&
          <OrderItemFileJobDetails
            job={focusedJob}
            onClose={handleCloseFocusedJob}
            onUpload={handleUpload}
            onCompletedToggle={handleCompleteToggle}
          />}
      </div>
    </div>
  );
};

export default OrderItemFiles;
