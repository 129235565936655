import React, {
  useCallback, useState,
} from 'react';
import {
  Field, FieldArray,
} from 'redux-form';
import {
  Grid, Box, Typography,
} from '@material-ui/core';
import ImageDropzone from '@components/shared/Upload/ImageDropzone';
import { dragAndDropMessage } from '@constants/values';
import Button from '@components/shared/Buttons/Button';
import ReduxFormCheckbox from '@sharedComponents/Form/ReduxFormCheckbox';
import ThreadColorDropDown from './ThreadColorDropDown';
import InfoField from '@sharedComponents/Display/InfoField';
import Input from '@sharedComponents/Form/Input';

const CustomItemPrintFilesSection = ({
  change, updateThreadColor, updateOptionInput, updateDesignInput,
}) => {
  const [
    uploadMessage,
    setUploadMessage,
  ] = useState({
    type: 'default',
    body: 'Drag \'n\' Drop',
  });

  const changeImage = (field, imageName, url, file, size) => {
    change(field.field, {
      file,
      size,
      imageName,
      id: field.printFile.id,
    });
  };

  const removeImage = (field) => {
    change(field.field, {
      file: null,
      size: field.size || field.printFile.size,
      imageName: null,
      imageUrl: null,
      id: field.printFile.id,
    });
  };

  const renderField = (field) => {
    const imageUrl = field.printFile?.file?.path || field.printFile.imageName || field.printFile.imageUrl
      || field.printFile.image_url;

    return (
      <Box>
        <ImageDropzone
          message={uploadMessage}
          imageUrl={imageUrl}
          size={field.printFile.size}
          changeImage={changeImage.bind(null, field)}
          showThumbnail={false}
          acceptedFileTypes={['.svg', '.ai', '.eps']}
        />
        <Box
          display='flex'
          justifyContent='center'
          pt={1}
        >
          {
            <Button
              type='secondary'
              text='Remove'
              onClick={removeImage.bind(null, field)}
              size='xs'
            />
          }
        </Box>
      </Box>
    );
  };

  const renderFields = ({ fields }) => {
    const printFiles = fields.getAll() || [];

    return (
      <>
        {fields.map((field, index) => (

          <Grid
            key={field}
            item={true}
            xs={3}
            style={{ margin: '2px' }}
          >
            <Box
              display='flex'
              justifyContent='center'
            >
              <Typography variant='p'>{printFiles[index].size || printFiles[index]}</Typography>
            </Box>

            <Field
              name={`${field}.file`}
              field={field}
              index={index}
              size={field.size}
              printFile={printFiles[index]}
              component={renderField}
            />
          </Grid>
        ))}
      </>
    );
  };

  return (
    <>
      <Grid
        container={true}
        direction='row'
        justifyContent='space-evenly'
        spacing={3}
      >

        <Grid
          item={true} xs={3}
        >
          <ThreadColorDropDown updateThreadColor={updateThreadColor} />
        </Grid>
        <Grid
          item={true} xs={3}
        >
          <InfoField
            fieldClass={'mb-0'}
            label={'Design ID'}
            value={(
              <Field
                name={'designId'}
                component={Input}
                type={'text'}
                className={'w-100'}
                onChange={updateDesignInput}
              />
            )}
          />
        </Grid>
        <Grid
          item={true} xs={3}
        >
          <InfoField
            fieldClass={'mb-0'}
            label={'Option ID'}
            value={(
              <Field
                name={'optionId'}
                component={Input}
                type={'text'}
                className={'w-100'}
                onChange={updateOptionInput}
              />
            )}
          />
        </Grid>

        <Grid
          item={true} xs={3}
        >
          <div className='info__field'>
            <Field
              name={'liquidPixelsAutomation'}
              text={'Automate Personalization'}
              component={ReduxFormCheckbox}
              change={change}
            />
          </div>
        </Grid>
      </Grid>
      <Grid
        container={true}
        direction='row'
        justifyContent='space-evenly'
        spacing={3}
      >
        <FieldArray
          name='printFiles'
          component={renderFields}
          rerenderOnEveryChange={true}
        />

      </Grid >
    </>
  );
};

export default CustomItemPrintFilesSection;
