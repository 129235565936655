import React from 'react';
import { scanOrderItemsBarcodesForm } from '@constants/reduxForms';
import { ScanBarcodesFormData } from '@models/forms/ScanBarcodesFormData';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import Modal from '@sharedComponents/Modal/Modal';
import ScanBarcodesForm from '../Forms/ScanBarcodesForm';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  onSubmit: (form: ScanBarcodesFormData) => void;
  itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[];
}

type Props = OwnProps;

const EditBarcodesModal = React.memo<Props>(({
  isOpen,
  closeModal,
  onSubmit,
  itemBarcodes,
}) => (
  <Modal
    title={'Edit Barcode(s)'}
    isOpen={isOpen}
    modalSize={'m'}
    closeModal={closeModal}
    buttons={(
      <ModalButtons
        confirmBtnText={'Save'}
        cancelBtnText={'Cancel'}
        onClose={closeModal}
        formSubmission={true}
        formId={scanOrderItemsBarcodesForm}
      />
    )}
  >
    <ScanBarcodesForm
      onSubmit={onSubmit}
      itemBarcodes={itemBarcodes}
    />
  </Modal>
));

export default EditBarcodesModal;
