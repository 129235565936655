import React, { Component } from 'react';
import {
  Field,
  FieldArray,
} from 'redux-form';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  getDiscountTypeOptions,
  getCouponReasonOptions,
} from '@constants/options/options';
import {
  discountTypeEnum,
  restrictionTypeValueEnum,
} from '@constants/enums/couponEnums';
import {
  generalOptions,
  createOptionsList,
} from '@util/optionsMap';
import { fetchOrganizationSuggestions } from '@redux/organizations/actions';
import AutocompleteInput from '@sharedComponents/Inputs/AutocompleteInput';
import { parseNumber } from '@util/numberHelpers';
import Input from '@sharedComponents/Form/Input';
import Select from '@sharedComponents/Form/Select';
import DatePicker from '@sharedComponents/Form/DatePicker';
import MaterialCheckbox from '@sharedComponents/Inputs/MaterialCheckbox';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import FormError from '@sharedComponents/Form/FormError';
import Icon from '@sharedComponents/Icons/Icon';
import { couponForm } from '@constants/reduxForms';

class CouponsForm extends Component {
  constructor(props) {
    super(props);
    const { initialValues } = this.props;

    let lockerCheckBox = false;
    let partnerCheckBox = false;
    let orderValueCheckBox = false;
    let redemptionLimitCheckBox = false;
    let styleCheckBox = false;
    let applyToShippingCheckBox = false;
    let organizationId = null;

    if (initialValues) {
      if (initialValues.organizationId) {
        organizationId = initialValues.organizationId;
      }
      if (initialValues.redemptionLimit > 0) {
        redemptionLimitCheckBox = true;
      }

      if (initialValues.styleCode) {
        styleCheckBox = true;
      }

      if (initialValues.applyToShipping) {
        applyToShippingCheckBox = true;
      }

      const restrictions = initialValues.restrictions || [];
      if (restrictions.length > 0) {
        const lockerRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Locker);
        if (lockerRestriction && lockerRestriction.value) {
          lockerCheckBox = true;
        }
        const partnerRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Partner);
        if (partnerRestriction && partnerRestriction.value) {
          partnerCheckBox = true;
        }
        const amountRestriction = restrictions.find((f) => f.restrictionType === restrictionTypeValueEnum.Amount);
        if (amountRestriction && amountRestriction.value) {
          orderValueCheckBox = true;
        }
      }
    } else {
      props.change('quantity', 1);
    }

    this.state = {
      selectedDiscountType: props.initialValues ? props.initialValues.discountType : null,
      restrictionsExpanded: true,
      lockerCheckBox,
      partnerCheckBox,
      orderValueCheckBox,
      redemptionLimitCheckBox,
      styleCheckBox,
      applyToShippingCheckBox,
      code: '',
      quantity: 1,
      selectedOrganization: organizationId ? {
        id: organizationId,
        name: initialValues.organizationName,
      } : null,
    };
  }

  handleSelectDiscountType = (e) => {
    const selectedDiscountType = parseInt(e.target.value);
    this.setState(() => ({ selectedDiscountType }));
  };

  fetchOrganizationSuggestions = (searchInput) => {
    const { dispatch } = this.props;
    dispatch(fetchOrganizationSuggestions(searchInput));
  };

  displayOrganization = (organization) => (`ORG${organization.id} - ${organization.name}`);

  selectOrganization = (selectedOrganization) => {
    const { change } = this.props;
    this.setState({ selectedOrganization }, () => {
      if (change) {
        change('organizationId', selectedOrganization.id);
      }
    });
  };

  handleChangeQuantity = (e) => {
    const quantity = parseInt(e.target.value);
    this.setState(() => ({ quantity }));
  };

  handleChangeCode = (e) => {
    const code = e.target.value.trim();
    this.setState(() => ({ code }));
  };

  toggleApplyToShipping = () => {
    const { change } = this.props;
    this.setState((prevState) => ({
      applyToShippingCheckBox: !prevState.applyToShippingCheckBox,
    }), () => {
      const { applyToShippingCheckBox } = this.state;
      change('applyToShipping', applyToShippingCheckBox);
    });
  };

  toggleLocker = () => {
    const { lockerCheckBox } = this.state;
    const { change } = this.props;
    if (lockerCheckBox && change) {
      change(`restrictions[${restrictionTypeValueEnum.Locker}].value`, '');
    }
    this.setState((prevState) => ({
      lockerCheckBox: !prevState.lockerCheckBox,
    }));
  };

  togglePartner = () => {
    const { partnerCheckBox } = this.state;
    const { change } = this.props;
    if (partnerCheckBox && change) {
      change(`restrictions[${restrictionTypeValueEnum.Partner}].value`, '');
    }

    this.setState((prevState) => ({
      partnerCheckBox: !prevState.partnerCheckBox,
    }));
  };

  toggleAmount = () => {
    const { orderValueCheckBox } = this.state;
    const { change } = this.props;
    if (orderValueCheckBox && change) {
      change(`restrictions[${restrictionTypeValueEnum.Amount}].value`, '');
    }

    this.setState((prevState) => ({
      orderValueCheckBox: !prevState.orderValueCheckBox,
    }));
  };

  toggleStyleCode = () => {
    const { styleCheckBox } = this.state;
    const { change } = this.props;
    if (styleCheckBox && change) {
      change('styleCode', '');
    }

    this.setState((prevState) => ({
      styleCheckBox: !prevState.styleCheckBox,
    }));
  };

  toggleRedemptionLimit = () => {
    const { redemptionLimitCheckBox } = this.state;
    const { change } = this.props;
    if (redemptionLimitCheckBox && change) {
      change('redemptionLimit', '');
    }

    this.setState((prevState) => ({
      redemptionLimitCheckBox: !prevState.redemptionLimitCheckBox,
    }));
  };

  renderRestrictions = ({
    fields,
    meta: { error },
  }) => {
    const { disabled = {} } = this.props;
    const {
      lockerCheckBox,
      partnerCheckBox,
      orderValueCheckBox,
    } = this.state;
    const fieldsInitialized = fields.getAll() || [];
    const lockerRestriction = fieldsInitialized.find((f) => f.restrictionType === restrictionTypeValueEnum.Locker);
    if (!lockerRestriction) {
      fields.push({ restrictionType: restrictionTypeValueEnum.Locker });
    }
    const partnerRestriction = fieldsInitialized.find((f) => f.restrictionType === restrictionTypeValueEnum.Partner);
    if (!partnerRestriction) {
      fields.push({ restrictionType: restrictionTypeValueEnum.Partner });
    }
    const amountRestriction = fieldsInitialized.find((f) => f.restrictionType === restrictionTypeValueEnum.Amount);
    if (!amountRestriction) {
      fields.push({ restrictionType: restrictionTypeValueEnum.Amount });
    }

    return (
      <ul className='redux-form__row'>
        <li key={0}>
          <MaterialCheckbox
            text={'Single Locker'}
            checked={lockerCheckBox}
            onClick={this.toggleLocker}
          />
          <Field
            name={`restrictions[${restrictionTypeValueEnum.Locker}].value`}
            placeholder={'Single Locker ID'}
            component={Input}
            type={'number'}
            parse={parseNumber}
            disabled={disabled.locker || !lockerCheckBox}
          />
        </li>
        <li key={1}>
          <MaterialCheckbox
            text={'Single Partner'}
            checked={partnerCheckBox}
            onClick={this.togglePartner}
          />
          <Field
            name={`restrictions[${restrictionTypeValueEnum.Partner}].value`}
            placeholder={'Single Partner ID'}
            component={Input}
            type={'text'}
            disabled={disabled.partner || !partnerCheckBox}
          />
        </li>
        <li key={2}>
          <MaterialCheckbox
            text={'Minimum Order Value'}
            checked={orderValueCheckBox}
            onClick={this.toggleAmount}
          />
          <div className='redux-form__input-wrapper'>
            <span className='icon-left'>$</span>
            <Field
              name={`restrictions[${restrictionTypeValueEnum.Amount}].value`}
              placeholder={'Minimum Order Value'}
              component={Input}
              type={'number'}
              parse={parseNumber}
              className={'has-icon'}
              disabled={disabled.orderValue || !orderValueCheckBox}
            />
          </div>
        </li>
        {error && <li className='error'>{error}</li>}
      </ul>
    );
  };

  render() {
    const {
      onSubmit,
      error,
      disabled = {},
      initialValues,
      organizations,
    } = this.props;

    const {
      selectedDiscountType,
      redemptionLimitCheckBox,
      styleCheckBox,
      selectedOrganization,
      quantity,
      code,
      applyToShippingCheckBox,
    } = this.state;

    const discountTypesOptions = generalOptions(getDiscountTypeOptions(true, 'Choose Discount Type'));
    const reasonOptions = generalOptions(getCouponReasonOptions(true, 'Choose Reason'));
    const isAddForm = !initialValues;

    return (
      <form
        className='redux-form'
        onSubmit={onSubmit}
        id={couponForm}
      >
        <div>
          <div className='tableView__details--header--title'>
            <span>Basic Information</span>
          </div>
          <div className='redux-form__section pb-5'>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-m'>
                <label className='redux-form__label'>
                  Coupon Code
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'code'}
                  placeholder={'Enter Code Name'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.code}
                  onChange={this.handleChangeCode}
                />
              </div>

              {
                isAddForm &&
                <div className='redux-form__column--size-m ml-20'>
                  <label className='redux-form__label has-icon'>
                    <span>
                      Quantity
                      <span className='required'>*</span>
                    </span>
                    {
                      !disabled.quantity && quantity > 1 &&
                      <MaterialTooltip
                        tooltipText={'The code you enter will be appended with a number for each coupon.'}
                        placement={'right'}
                      >
                        <Icon
                          materialIcon={'warning'}
                          classes={'redux-form__icon orange ml-5'}
                        />
                      </MaterialTooltip>
                    }
                  </label>
                  <Field
                    name={'quantity'}
                    placeholder={'Enter Quantity'}
                    component={Input}
                    type={'number'}
                    parse={parseNumber}
                    disabled={disabled.quantity}
                    onChange={this.handleChangeQuantity}
                  />
                </div>
              }
            </div>

            {
              !disabled.quantity && quantity > 1 && code &&
              <div className='mb-5'>
                Coupon codes that are going to be generated:&nbsp;
                {code}{'0'.repeat(quantity.toString().length - 1)}1...{code}{quantity}
              </div>
            }

            <div>
              <label className='redux-form__label'>
                Description
                <span className='required'>*</span>
              </label>
              <Field
                name={'description'}
                placeholder={'Enter Code Description'}
                component={Input}
                type={'text'}
                disabled={disabled.description}
              />
            </div>

            <div className='redux-form__row'>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Reason
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'reason'}
                  component={Select}
                  disabled={disabled.reason}
                >
                  {reasonOptions}
                </Field>
              </div>

              <div className='redux-form__column--size-s ml-20'>
                <label className='redux-form__label'>
                  Discount type
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'discountType'}
                  component={Select}
                  disabled={disabled.discountType}
                  onChange={this.handleSelectDiscountType}
                >
                  {discountTypesOptions}
                </Field>
              </div>

              <div className='redux-form__column--size-s ml-20'>
                <label className='redux-form__label'>
                  Discount amount
                  <span className='required'>*</span>
                </label>
                <div className='redux-form__input-wrapper'>
                  {selectedDiscountType === discountTypeEnum.DollarAmount && <span className='icon-left'>$</span>}
                  <Field
                    name={'discountAmount'}
                    placeholder={'Enter Amount'}
                    component={Input}
                    type={'number'}
                    parse={parseNumber}
                    disabled={disabled.discountAmount}
                    className={
                      `w-100 ${selectedDiscountType === discountTypeEnum.DollarAmount ? 'has-icon' : ''} ${selectedDiscountType === discountTypeEnum.Percentage ? 'has-icon--right' : ''}`
                    }
                  />
                  {selectedDiscountType === discountTypeEnum.Percentage && <span className='icon-right'>%</span>}
                </div>
              </div>
            </div>

            <div className='redux-form__row justify__start'>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label'>
                  Valid from
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'validFrom'}
                  placeholder={'Choose Start Date'}
                  component={DatePicker}
                  disabled={disabled.validFrom}
                />
              </div>
              <div className='redux-form__column--size-s  ml-40'>
                <label className='redux-form__label'>
                  Valid to
                  <span className='required'>*</span>
                </label>
                <Field
                  name={'validTo'}
                  placeholder={'Choose End Date'}
                  component={DatePicker}
                  disabled={disabled.validTo}
                />
              </div>
              {
                selectedDiscountType === discountTypeEnum.DollarAmount &&
                <div className='redux-form__column--size-s redux-form__row-aligned ml-40'>
                  <MaterialCheckbox
                    text={'Apply To Shipping'}
                    checked={applyToShippingCheckBox}
                    onClick={this.toggleApplyToShipping}
                  />
                </div>
              }
            </div>
          </div>
          <div className='tableView__details--header--title mb-10'>
            <span>Restrictions</span>
          </div>
          <div className='redux-form__section gray pt-5 pb-5'>
            <div className='redux-form__row'>
              <div className='redux-form__column--size-s'>
                <MaterialCheckbox
                  text={'Style Number'}
                  checked={styleCheckBox}
                  onClick={this.toggleStyleCode}
                />
                <Field
                  name={'styleCode'}
                  placeholder={'Enter Style Number'}
                  component={Input}
                  type={'text'}
                  disabled={disabled.styleCode || !styleCheckBox}
                />
              </div>
              <div className='redux-form__column--size-s'>
                <MaterialCheckbox
                  text={'Redemption Limit'}
                  checked={redemptionLimitCheckBox}
                  onClick={this.toggleRedemptionLimit}
                />
                <Field
                  name={'redemptionLimit'}
                  placeholder={'Enter Redemption Limit'}
                  component={Input}
                  type={'number'}
                  parse={parseNumber}
                  disabled={disabled.redemptionLimit || !redemptionLimitCheckBox}
                />
              </div>
              <div className='redux-form__column--size-s'>
                <label className='redux-form__label mb-10 mt-5'>
                  Organization
                </label>
                <div className='select-field'>
                  <AutocompleteInput
                    suggestions={organizations}
                    fetchSuggestions={this.fetchOrganizationSuggestions}
                    displayItem={this.displayOrganization}
                    displaySuggestionText={this.displayOrganization}
                    selectedItem={selectedOrganization}
                    selectItem={this.selectOrganization}
                    placeholder={'Enter organization'}
                    keyValue={selectedOrganization ? selectedOrganization.id : 0}
                    isDropdownFixed={true}
                  />
                </div>
              </div>
            </div>
            <FieldArray
              name={'restrictions'}
              component={this.renderRestrictions}
              rerenderOnEveryChange={true}
              props={this.state}
            />
          </div>
          <FormError error={error} />
        </div>
      </form>
    );
  }
}

CouponsForm.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  initialValues: PropTypes.object,
  error: PropTypes.string,
  disabled: PropTypes.object,
  change: PropTypes.func,
  organizations: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const mapStateToProps = ({ organizations }) => ({
  organizations: organizations.organizationSuggestions,
});

export default connect(mapStateToProps)(CouponsForm);
