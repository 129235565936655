import React, { useCallback } from 'react';
import { QueryObserverResult } from 'react-query';
import {
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin,
} from '@api/fulfillment/production-assembly-new';
import {
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import { DecorationMethodEnum } from '@api/productCatalog/models';
import {
  checkInNotCheckedStatuses,
  checkInEnabledStatuses,
} from '@constants/production';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  isAssemblyDisabled?: boolean;
  orderNumber: number;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
  openAddBarcodesModal: (checkInButtonIsClicked: boolean) => void;
}

type Props = OwnProps;

const ReviewItemActions = React.memo<Props>(({
  item,
  isAssemblyDisabled,
  orderNumber,
  refreshOrderItemGroups,
  refreshOrder,
  openAddBarcodesModal,
}) => {
  const {
    lineLevel1, hfBarcodeNotRequiredForDtg,
  } = useFlags();

  const { mutateAsync: markOrderItemCheckedIn }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkcheckedin();

  const { mutateAsync: unmarkOrderItemCheckedIn }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkcheckedin();

  const selectCheckedInButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    if (itemStatus === orderItemStatusEnum.InPicking
      || itemStatus === orderItemStatusEnum.Picked) {
      let hasBarcodes = true;
      for (const orderItemId of item.orderItemIds!) {
        const barcode: string | null = item.orderItemIdsWithBarcode![orderItemId];

        if (!barcode) {
          hasBarcodes = false;
          break;
        }
      }

      const isDtg = item.decorationMethod === DecorationMethodEnum.DTG;

      if (!hasBarcodes && (
        (!lineLevel1 && isDtg && !hfBarcodeNotRequiredForDtg)
        || (lineLevel1 && isDtg && !hfBarcodeNotRequiredForDtg)
        || (lineLevel1 && !isDtg))) {
        openAddBarcodesModal(true);
      } else {
        await markOrderItemCheckedIn({
          orderNumber,
          data: model,
        });

        refreshOrderItemGroups();
        refreshOrder();
      }
    } else if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
      await unmarkOrderItemCheckedIn({
        orderNumber,
        data: model,
      });

      refreshOrderItemGroups();
      refreshOrder();
    }
  }, [
    refreshOrderItemGroups,
    refreshOrder,
    orderNumber,
    item,
    markOrderItemCheckedIn,
    unmarkOrderItemCheckedIn,
    openAddBarcodesModal,
    lineLevel1,
    hfBarcodeNotRequiredForDtg,
  ]);

  if (!item) return null;

  const itemStatus = item.status?.status;

  const checkInChecked = !checkInNotCheckedStatuses.includes(itemStatus as typeof checkInNotCheckedStatuses[number]);

  const checkInDisabled = isAssemblyDisabled
    ?? !checkInEnabledStatuses.includes(itemStatus as typeof checkInEnabledStatuses[number]);

  return (
    <div className='actions mr-15'>
      <MaterialTooltip
        tooltipText={itemStatus === orderItemStatusEnum.InPicking
          || itemStatus === orderItemStatusEnum.Initial
          ? '' : 'Check In'
        }
        placement={'right'}
      >
        <CheckButton
          disabled={checkInDisabled}
          text={'Check In'}
          checked={checkInChecked}
          onClick={selectCheckedInButton}
        />
      </MaterialTooltip>
    </div>
  );
});

export default ReviewItemActions;
