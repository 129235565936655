import React, {
  useCallback, useMemo, useState,
} from 'react';
import {
  DecorationMethodEnum,
  ProductDetailDto,
} from '@api/productCatalog/models';
import { ProductionAssemblyItemGroupDto } from '@api/fulfillment/models';
import ReviewItemBarcodes from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemBarcodes';
import ReviewItemLabelsLegendDisplay from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemLabelsLegendDisplay';
import AddButton from '@sharedComponents/Display/AddButton';
import EditButton from '@sharedComponents/Display/EditButton';
import ReviewItemInfoTooltip from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemInfoTooltip';
import ReviewItemArrivalStatus from './ReviewItemArrivalStatus';
import { useFlags } from 'launchdarkly-react-client-sdk';
import EditOrderItemStatusModal from './EditOrderItemStatusModal';
import { RootState } from '@redux/index/reducers';
import {
  checkPermissions, roleMapping,
} from '@util/roleCheck';
import {
  connect, ConnectedProps,
} from 'react-redux';
import PermissionsEnum from '@constants/enums/permissionsEnum';

interface OwnProps {
  item: ProductionAssemblyItemGroupDto;
  product: ProductDetailDto | undefined;
  itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[];
  openAddBarcodesModal: () => void;
  openEditBarcodesModal: () => void;
  showArrivalStatus?: boolean;
  showItemStatus?: boolean;
}

const mapStateToProps = ({ oidc }: RootState) => ({
  roles: roleMapping(oidc),
});

const connector = connect(mapStateToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ReviewItemHeader = React.memo<Props>(({
  item,
  product,
  itemBarcodes,
  openAddBarcodesModal,
  openEditBarcodesModal,
  showArrivalStatus = false,
  showItemStatus = false,
  roles,
}) => {
  const [
    editItemStatusModalIsOpen,
    setEditItemStatusModalIsOpen,
  ] = useState<boolean>(false);

  const itemBarcodesNonEmpty = useMemo(() => (
    itemBarcodes.filter((ib) => !!ib.barcode) || []
  ), [itemBarcodes]);
  const { lineLevel1 } = useFlags();

  const openEditItemStatusModal = useCallback(() => {
    setEditItemStatusModalIsOpen(true);
  }, []);

  const closeEditItemStatusModal = useCallback(() => {
    setEditItemStatusModalIsOpen(false);
  }, []);

  if (!item) return null;

  return (
    <div className='details'>
      <EditOrderItemStatusModal
        isOpen={editItemStatusModalIsOpen}
        closeModal={closeEditItemStatusModal}
        item={item}
        product={product!}
      />
      <div className='details__product'>
        <div className='details__product__title'>
          <span>{item.sku}</span>
          <ReviewItemInfoTooltip itemProduct={product} />
          <div className='border' />
          <span className='details__product__title--name'>
            <span>{product?.coloredStyle?.style?.name}</span>
            <ReviewItemLabelsLegendDisplay item={item} />
          </span>
        </div>
        <div className='align__center'>
          <div className='details__product__title'>Qty: {item.quantity}</div>
          {
            !!item.cartLocations?.length &&
            <>
              <div className='border' />
              <div className='details__product__title'>
                Cart: {item.cartLocations.join(', ')}
              </div>
            </>
          }
          <div className='details__product--minor mt-0 ml-10'>
            {
              showArrivalStatus &&
              <ReviewItemArrivalStatus item={item} />
            }
            {
              (item.decorationMethod === DecorationMethodEnum.DTG || lineLevel1) &&
              <>
                <ReviewItemBarcodes itemBarcodes={itemBarcodesNonEmpty} />
                {
                  itemBarcodesNonEmpty.length > 0
                    ? (
                      <EditButton
                        text={'Edit Barcode(s)'}
                        classes={'ml-0 align__center'}
                        onClick={openEditBarcodesModal}
                      />
                    )
                    : (
                      <AddButton
                        text={'Add Barcode(s)'}
                        classes={'align__center'}
                        onClick={openAddBarcodesModal}
                      />
                    )
                }
              </>
            }
            {
              showItemStatus
              &&
              <>
                <div className='border' />
                {item.status?.label}
                {
                  checkPermissions(roles, PermissionsEnum.OrderItemStatusEdit) &&
                  <EditButton
                    onClick={openEditItemStatusModal}
                    text={'EDIT'}
                  />
                }
              </>
            }
          </div>
        </div>
      </div>
    </div>
  );
});

export default connector(ReviewItemHeader);
