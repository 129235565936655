import React from 'react';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';

interface OwnProps {
  itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[];
}

type Props = OwnProps;

const ReviewItemBarcodes = React.memo<Props>(({ itemBarcodes }) => {
  if (itemBarcodes.length > 1) {
    return (
      <MaterialTooltip
        tooltipText={(
          <div>
            {
              itemBarcodes.map((ib) => (
                <div key={ib.orderItemId}>{ib.barcode}</div>
              ))
            }
          </div>
        )}
        contentClasses={'flex'}
        interactive={true}
        placement={'top'}
      >
        <span className='details__product--label'>Barcodes: {itemBarcodes.length}</span>
      </MaterialTooltip>
    );
  }

  if (itemBarcodes.length === 1) {
    return (
      <span className='details__product--label'>Barcode: {itemBarcodes[0].barcode}</span>
    );
  }

  return null;
});

export default ReviewItemBarcodes;
