import { OrderDetailsDto, UpdateOrderItemPriceCalculationDto } from '@api/fulfillment/models';
import ModalButtons from '@components/shared/Modal/ModalButtons';
import { accountingActionTypeEnum } from '@constants/enums/orderEnums';
import { formatDollarAmount } from '@util/numberHelpers';
import React from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import OrderManagementExpandablePriceTab from './OrderManagementExpandablePriceTab';
import { paymentMethodAllowsChargeAhead } from '@util/paymentHelper';

interface OwnProps {
  isOpen: boolean;
  closeModal: () => void;
  orderItemReplacePriceCalculation: UpdateOrderItemPriceCalculationDto;
  replace: () => void;
  order: OrderDetailsDto;
}

type Props = OwnProps;

const OrderManagementDetailsReplaceBySkuConfirmationModalOld = React.memo<Props>(({
  isOpen,
  closeModal,
  orderItemReplacePriceCalculation,
  replace,
  order,
}) => {
  const orderItem = orderItemReplacePriceCalculation?.orderItems
    ? orderItemReplacePriceCalculation.orderItems[0]
    : null;
  const updatedOrderItem = orderItemReplacePriceCalculation?.updatedOrderItems
    ? orderItemReplacePriceCalculation.updatedOrderItems[0]
    : null;

  const paymentType = orderItemReplacePriceCalculation && orderItemReplacePriceCalculation.pretaxAmountToChange! < 0
    ? accountingActionTypeEnum.Refund
    : accountingActionTypeEnum.Charge;

  const taxAmountToChange = orderItemReplacePriceCalculation?.taxAmountToChange ?? 0;
  const pretaxAmountToChange = orderItemReplacePriceCalculation?.pretaxAmountToChange ?? 0;

  return (
    <Modal
      isOpen={isOpen}
      closeModal={closeModal}
      modalHeight={'l'}
      modalWidth={'m'}
      isPadded={true}
      title={'Replace by SKU - Price Confirmation'}
      buttons={(
        <ModalButtons
          confirmBtnText={'Replace'}
          cancelBtnText={'Cancel'}
          onConfirm={replace}
          onClose={closeModal}
          isDangerousAction={true}
          confirmBtnDisabled={orderItemReplacePriceCalculation && !orderItemReplacePriceCalculation.canUpdate}
          warningMessage={
            !paymentMethodAllowsChargeAhead(order.stripePaymentMethod)
              ? 'The payment method used for this order does not allow for charge ahead.  Make sure the amount is zero before proceeding.'
              : undefined
          }
        />
      )}
    >
      <div>
        {
          isOpen &&
          <div>
            <OrderManagementExpandablePriceTab
              label={'Order item'}
              subLabel={'(before tax)'}
              disableExpand={true}
              content={(
                <div>
                  <div className='order-financial-details__content'>
                    <div className='justify__space-between'>
                      <span>Base price</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(updatedOrderItem?.basePrice)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.basePrice)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Style discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem?.styleDiscountAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.styleDiscountAmount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Locker discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem?.lockerDiscountAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.lockerDiscountAmount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising base amount</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(updatedOrderItem?.fundraisingBaseAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.fundraisingBaseAmount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising coupon discount</span>
                      <div>
                        <span className='negative-amount mr-5'>- {formatDollarAmount(updatedOrderItem?.fundraisingCouponDiscount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.fundraisingCouponDiscount)})</span>
                      </div>
                    </div>
                    <div className='justify__space-between mt-15'>
                      <span>Fundraising amount</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(updatedOrderItem?.fundraisingAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.fundraisingAmount)})</span>
                      </div>
                    </div>
                  </div>
                  <div className='order-financial-details__subtotal'>
                    <div>
                      <div className='order-financial-details__subtotal--explanation'>(quantity: {orderItem?.quantity})</div>
                      <span>{paymentType} amount</span>
                    </div>
                    <span className='align__flex-end'>{formatDollarAmount(Math.abs(pretaxAmountToChange))}</span>
                  </div>
                </div>
              )}
            />

            <OrderManagementExpandablePriceTab
              label={'Tax'}
              disableExpand={true}
              classes={'mt-30'}
              content={(
                <div>
                  <div className='order-financial-details__content'>
                    <div className='justify__space-between'>
                      <span>Tax</span>
                      <div>
                        <span className='current-amount'>{formatDollarAmount(updatedOrderItem?.taxAmount)}</span>
                        <span className='line-through'>({formatDollarAmount(orderItem?.taxAmount)})</span>
                      </div>
                    </div>
                  </div>
                  <div className='order-financial-details__subtotal'>
                    <div>
                      <div className='order-financial-details__subtotal--explanation'>(quantity: {orderItem?.quantity})</div>
                      <span>{paymentType} tax amount</span>
                    </div>
                    <span className='align__flex-end'>{formatDollarAmount(Math.abs(taxAmountToChange))}</span>
                  </div>
                </div>
              )}
            />

            <div className='mt-30'>
              <div className='order-financial-details__total--explanation'>(quantity: {orderItem?.quantity})</div>
              <div className='order-financial-details__total'>
                <span>{paymentType} Total Amount</span>
                <span>
                  {formatDollarAmount(Math.abs(pretaxAmountToChange)
                    + Math.abs(taxAmountToChange))}
                </span>
              </div>
            </div>

            {
              orderItemReplacePriceCalculation && !orderItemReplacePriceCalculation.canUpdate &&
              <div className='redux-form__error'>Warning: Can't replace SKU.</div>
            }
          </div>
        }
      </div>
    </Modal>
  );
});

export default OrderManagementDetailsReplaceBySkuConfirmationModalOld;
