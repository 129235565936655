import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { reset } from 'redux-form';
import PropTypes from 'prop-types';
import {
  getColoredStyle,
  getProductsForStyle,
} from '@redux/productCatalog/actions';
import { updateCustomItem } from '@redux/lockerManager/actions';
import { customItemForm } from '@constants/reduxForms';
import { customItemStatusEnum } from '@constants/enums/lockerEnums';
import { fetchVendorsFilter } from '@redux/vendorOrders/actions';
import { materialSwal } from '@util/componentHelper';
import {
  formatNumber,
  toCents,
} from '@util/numberHelpers';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import CustomizeItem from '@sharedComponents/CustomItems/CustomizeItem/CustomizeItem';
import InvalidDesignIdModal from './InvalidDesignIdModal';
import { isDefined } from '@util/valueHelpers';

class EditCustomItemModal extends PureComponent {
  state = {
    selectedColoredStyle: this.props.coloredStyle,
    invalidDesignIdModalIsOpen: false,
    changeSkuModalIsOpen: false,
    submissionDisabled: false,
  };

  componentDidMount() {
    const {
      dispatch,
      customItem,
    } = this.props;

    dispatch(fetchVendorsFilter());

    if (customItem) {
      dispatch(getColoredStyle(customItem.coloredStyleId));
    }
  }

  componentDidUpdate(prevProps) {
    const {
      dispatch,
      customItem,
      coloredStyle,
    } = this.props;

    if (customItem && (!prevProps.customItem || customItem.id !== prevProps.customItem.id)) {
      dispatch(getColoredStyle(customItem.coloredStyleId));
    }

    if (coloredStyle && (!prevProps.coloredStyle || coloredStyle !== prevProps.coloredStyle)) {
      dispatch(getProductsForStyle(coloredStyle.style.id));
      this.setState(() => ({ selectedColoredStyle: coloredStyle }));
    }
  }

  openInvalidDesignIdModal = () => {
    this.setState(() => ({ invalidDesignIdModalIsOpen: true }));
  };

  closeInvalidDesignIdModal = () => {
    this.setState(() => ({ invalidDesignIdModalIsOpen: false }));
  };

  openChangeSkuModal = () => {
    this.setState(() => ({ changeSkuModalIsOpen: true }));
  };

  closeChangeSkuModal = () => {
    this.setState(() => ({ changeSkuModalIsOpen: false }));
  };

  updateCustomItem = async (updatedCustomItem) => {
    const {
      lockerId,
      dispatch,
      refresh,
    } = this.props;

    const { selectedColoredStyle } = this.state;

    const customItem = { ...updatedCustomItem };

    if (!customItem.imageFront && !customItem.imageBack && !customItem.imageLeft && !customItem.imageRight) {
      customItem.imageFront = selectedColoredStyle.imageUrlFront;
    }

    customItem.price = toCents(customItem.price);
    customItem.fundraisingAmount = isDefined(customItem.fundraisingAmount)
      ? toCents(customItem.fundraisingAmount)
      : 0;

    const res = await updateCustomItem(customItem, lockerId, selectedColoredStyle.id);

    if (!customItem.isValidDesignId) {
      this.openInvalidDesignIdModal();
    } else {
      materialSwal('Success', res.message, 'success');
    }

    await dispatch(reset(customItemForm));
    refresh();

    this.onCloseModal();
  };

  onCloseModal = () => {
    const { closeModal } = this.props;

    this.setState(() => ({
      selectedColoredStyle: null,
      changeSkuModalIsOpen: false,
    }), closeModal);
  };

  changeColoredStyle = (selectedColoredStyle) => {
    this.setState(() => ({ selectedColoredStyle }));
  };

  changeSubmissionDisabled = (submissionDisabled) => {
    this.setState(() => ({ submissionDisabled }));
  };

  render() {
    const {
      isOpen,
      lockerId,
      customItem,
    } = this.props;

    const {
      selectedColoredStyle,
      invalidDesignIdModalIsOpen,
      changeSkuModalIsOpen,
      submissionDisabled,
    } = this.state;

    let initialValues;
    let disabled = {};

    if (customItem) {
      initialValues = {
        id: customItem.id,
        isCustomItem: customItem.isCustomItem,
        status: customItem.status,
        customItemIsHidden: customItem.status === customItemStatusEnum.Hidden,
        imageFront: customItem.imageFront,
        imageBack: customItem.imageBack,
        imageRight: customItem.imageRight,
        imageLeft: customItem.imageLeft,
        discounts: customItem.discounts,
        price: formatNumber(customItem.price / 100),
        customName: customItem.customName,
        vendorDesignId: customItem.vendorDesignId,
        personalizationItemType: customItem.personalizationItemType,
        decorations: customItem.decorations,
        fundraisingAmount: customItem.fundraisingAmount
          ? formatNumber(customItem.fundraisingAmount / 100)
          : null,
        requiredItem: customItem.requiredItem,
        timelineDays: customItem.timelineDays,
        timelineUuid: customItem.timelineUuid,
        threadColor: customItem.threadColor,
        designId: customItem.designId,
        optionId: customItem.optionId,
        liquidPixelsAutomation: customItem.liquidPixelsAutomation,
        maxPersonalizationNameLength: customItem.maxPersonalizationNameLength,
        printFiles: customItem.locker_item_print_files,
      };

      disabled = {
        discounts: !customItem.isCustomItem,
        customName: !customItem.isCustomItem,
        fundraisingAmount: !customItem.isCustomItem,
        requiredItem: !customItem.isCustomItem,
        customItemIsHidden: !customItem.isCustomItem,
      };
    }

    return (
      <>
        <InvalidDesignIdModal
          isOpen={invalidDesignIdModalIsOpen}
          closeModal={this.closeInvalidDesignIdModal}
        />
        <Modal
          title={customItem?.isCustomItem ? 'Edit Custom Item' : 'Edit Item'}
          modalHeight={'l'}
          modalWidth={changeSkuModalIsOpen ? 'xl' : 'l'}
          isOpen={isOpen}
          closeModal={this.onCloseModal}
          buttons={(
            <ModalButtons
              confirmBtnText={'Save'}
              cancelBtnText={'Cancel'}
              confirmBtnDisabled={submissionDisabled}
              onClose={this.onCloseModal}
              formSubmission={true}
              formId={customItemForm}
            />
          )}
        >
          <div>
            {
              selectedColoredStyle &&
              <CustomizeItem
                lockerId={lockerId}
                selectedColoredStyle={selectedColoredStyle}
                changeColoredStyle={this.changeColoredStyle}
                saveCustomItem={this.updateCustomItem}
                initialValues={initialValues}
                changeSkuModalIsOpen={changeSkuModalIsOpen}
                openChangeSkuModal={this.openChangeSkuModal}
                closeChangeSkuModal={this.closeChangeSkuModal}
                changeSubmissionDisabled={this.changeSubmissionDisabled}
                submissionDisabled={submissionDisabled}
                disabledFields={disabled}
                edit={true}
              />
            }
          </div>
        </Modal>
      </>
    );
  }
}

EditCustomItemModal.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  closeModal: PropTypes.func.isRequired,
  refresh: PropTypes.func.isRequired,
  lockerId: PropTypes.number.isRequired,
  coloredStyle: PropTypes.object,
  coloredStyleId: PropTypes.number,
  customItem: PropTypes.object,
};

const mapStateToProps = ({ productCatalog }) => ({
  decorationLocations: productCatalog.decorationLocations,
  coloredStyle: productCatalog.coloredStyleInfo,
});

export default connect(mapStateToProps)(EditCustomItemModal);
