import React, {
  useEffect,
  useState,
  useCallback,
  useMemo,
} from 'react';
import Typography from '@material-ui/core/Typography';
import { useHistory, Link } from 'react-router-dom';
import Table from '@sharedComponents/Table/Table';
import { BatchWarehouseCategoryEnum, BatchWarehouseGroupingDateTypeEnum, BatchWarehouseOrderGroupItemDto } from '@api/fulfillment/models';
import { useGetHomefieldApiWarehouseschedulingOrdergroups } from '@api/fulfillment/warehouse-scheduling';
import { parseDateNumeric } from '@util/dateHandler';
import DateInput from '@components/shared/Inputs/DateInput';
import moment from 'moment';
import Dropdown from '@components/shared/Inputs/Dropdowns/Dropdown';
import MaterialTooltip from '@components/shared/Tooltips/MaterialTooltip';
import Icon from '@components/shared/Icons/Icon';
import useHeaderContext from '@components/shared/Contexts/HeaderContext';
import SearchFilter from '@components/shared/Inputs/SearchFilter';
import { keyNameEnum } from '@constants/enums/commonEnums';
import { batchWarehouseSchedulingListUrlWithParams } from '@constants/clientUrls/clientUrls';
import BatchWarehouseSchedulingViewOptions from './BatchWarehouseSchedulingViewOptions';
import { dropdownSizeL } from '@constants/values';
import MultiSelectDropdown from '@components/shared/Inputs/Dropdowns/MultiSelectDropdown/MultiSelectDropdown';

type PartnerDto = {
  id: string;
};

type VendorDto = {
  id: string;
};

const GroupingTable = Table<BatchWarehouseOrderGroupItemDto>();

const BatchWarehouseSchedulingTabs = {
  MainScheduling: 'MainScheduling',
  SchedulingCategories: 'SchedulingCategories',
  PostDispatch: 'PostDispatch',
} as const;

type BatchWarehouseSchedulingTabsType = keyof typeof BatchWarehouseSchedulingTabs;

export const BatchWarehouseScheduling: React.FC = React.memo(() => {
  const history = useHistory();

  const [groupingType, setGroupingType] = useState<BatchWarehouseGroupingDateTypeEnum>(
    BatchWarehouseGroupingDateTypeEnum.Shipping
  );

  const [showOrders, setShowOrders] = useState<boolean>(true);
  const [showUnits, setShowUnits] = useState<boolean>(true);
  const [showDecorations, setShowDecorations] = useState<boolean>(true);

  const [minDate, setMinDate] = useState<string>(
    moment().add(-14, 'days')
      .format('YYYY-MM-DD')
  );

  const [maxDate, setMaxDate] = useState<string>(
    moment().add(14, 'days')
      .format('YYYY-MM-DD')
  );

  const [selectedPartners, setSelectedPartners] = useState<string[]>([]);
  const [selectedVendors, setSelectedVendors] = useState<string[]>([]);

  const [activeTab, setActiveTab] = useState<BatchWarehouseSchedulingTabsType>(
    BatchWarehouseSchedulingTabs.MainScheduling
  );

  const { headerMutator } = useHeaderContext();

  const {
    data,
    refetch,
  } = useGetHomefieldApiWarehouseschedulingOrdergroups({
    partners: selectedPartners.length > 0 ? selectedPartners : undefined,
    vendors: selectedVendors.length > 0 ? selectedVendors : undefined,
    minDate,
    maxDate,
    groupingDateType: BatchWarehouseGroupingDateTypeEnum.Shipping,
  }, { query: { enabled: true } });

  type DtoKey = keyof BatchWarehouseOrderGroupItemDto;
  type ColumnCellPropsType<T extends DtoKey | unknown = unknown> = {
    original: BatchWarehouseOrderGroupItemDto;
    value: T extends DtoKey ? BatchWarehouseOrderGroupItemDto[T] : unknown;
  };

  const toggleShowOrders = useCallback(() => {
    setShowOrders(!showOrders);
  }, [showOrders]);

  const toggleShowUnits = useCallback(() => {
    setShowUnits(!showUnits);
  }, [showUnits]);

  const toggleShowDecorations = useCallback(() => {
    setShowDecorations(!showDecorations);
  }, [showDecorations]);

  const shippingColumn = useMemo(() => ({
    Header: `${groupingType} Date`,
    accessor: 'groupDate',
    minWidth: 170,
    Cell: (cellProps: ColumnCellPropsType) => {
      const date = parseDateNumeric(cellProps.original.groupDate);
      const allShipped = (cellProps.original?.totalUnits ?? 0) <= (cellProps.original?.shippedUnits ?? 0);
      const dateShipped = allShipped && cellProps.original.lastShipDate ? parseDateNumeric(cellProps.original.lastShipDate) : '';

      return (
        <MaterialTooltip
          tooltipText={allShipped ? `All Shipped on ${dateShipped}` : 'Not All Shipped'}
          placement='bottom'
          contentClasses={'flex'}
        >
          <div>
            {
              allShipped
                ? <Icon materialIcon={'check'} />
                : <Icon materialIcon={'build'} />
            }
            {'  '}
            <span>{date}</span>
          </div>
        </MaterialTooltip>
      );
    },
    Footer: () => <Typography variant='body1'><b>Total</b></Typography>,
  }), [groupingType]);

  const orderAndUnitPair = useCallback((
    name: string,
    ordersKey: DtoKey,
    unitsKey: DtoKey,
    decorationsKey: DtoKey,
    width: number
  ) => {
    const targetDateCb = (cellProps: ColumnCellPropsType) => moment(cellProps.original.groupDate).format('YYYY-MM-DD');

    const getParts = (dto: BatchWarehouseOrderGroupItemDto, includeLabels: boolean = false) => {
      const parts = [];
      if (showOrders) parts.push(includeLabels ? `${dto[ordersKey]} Orders` : dto[ordersKey]);
      if (showUnits) parts.push(includeLabels ? `${dto[unitsKey]} Units` : dto[unitsKey]);
      if (showDecorations) parts.push(includeLabels ? `${dto[decorationsKey]} Decorations` : dto[decorationsKey]);

      return parts;
    };

    const getValuesString = (dto: BatchWarehouseOrderGroupItemDto) => {
      const parts = getParts(dto);

      return parts.length === 0 ? '' : parts.join(' / ');
    };

    const getTooltipText = (dto: BatchWarehouseOrderGroupItemDto) => {
      const parts = getParts(dto, true);

      return parts.length === 0 ? '' : `${name} ${parts.join(' / ')} for ${parseDateNumeric(dto.groupDate)}`;
    };

    const renderFooter = () => {
      const parts = [];
      const tipParts = [];

      if (data?.sums) {
        if (showOrders) {
          parts.push(data.sums[ordersKey]);
          tipParts.push(`${data.sums[ordersKey]} Orders`);
        }
        if (showUnits) {
          parts.push(data.sums[unitsKey]);
          tipParts.push(`${data.sums[unitsKey]} Units`);
        }
        if (showDecorations) {
          parts.push(data.sums[decorationsKey]);
          tipParts.push(`${data.sums[decorationsKey]} Decorations`);
        }
      }

      return (
        <MaterialTooltip tooltipText={tipParts.join(' / ')} placement='top'>
          <Typography variant='body1'><b>{parts.join(' / ')}</b></Typography>
        </MaterialTooltip>
      );
    };

    return {
      Header: name,
      id: '',
      minWidth: width,
      pin: true,
      Cell: (cellProps: ColumnCellPropsType) => (
        <MaterialTooltip
          tooltipText={getTooltipText(cellProps.original)}
          placement='bottom'
          contentClasses={'flex'}
        >
          <Link to={batchWarehouseSchedulingListUrlWithParams({
            groupingDateType: groupingType,
            maxDate: targetDateCb(cellProps),
            minDate: targetDateCb(cellProps),
            category: ordersKey as BatchWarehouseCategoryEnum,
            partners: selectedPartners.join('$'),
            vendors: selectedVendors.join('$'),
          })}
          >
            {getValuesString(cellProps.original)}
          </Link>
        </MaterialTooltip>
      ),
      Footer: () => renderFooter(),
    };
  }, [
    groupingType, showOrders, showUnits, showDecorations, data?.sums, selectedPartners, selectedVendors,
  ]);

  const mainColumnWidth = 250;
  const mainColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Total', 'totalOrders', 'totalUnits', 'totalDecorations', mainColumnWidth),
    orderAndUnitPair('Ready', 'readyOrders', 'readyUnits', 'readyDecorations', mainColumnWidth),
    orderAndUnitPair('Partial Ready', 'partialReadyOrders', 'partialReadyUnits', 'partialReadyDecorations', mainColumnWidth),
    orderAndUnitPair('Backordered', 'backorderedOrders', 'backorderedUnits', 'backorderedDecorations', mainColumnWidth),
    orderAndUnitPair('Needs Print', 'needPrintOrders', 'needPrintUnits', 'needPrintDecorations', mainColumnWidth),
  ]), [shippingColumn, orderAndUnitPair]);

  const schCategoryColumnWidth = 208;
  const schCategoryColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Ready HAG Only', 'readyHagOnlyOrders', 'readyHagOnlyUnits', 'readyAllDecDecorations', schCategoryColumnWidth),
    orderAndUnitPair('Ready EMB Only', 'readyEmbOnlyOrders', 'readyEmbOnlyUnits', 'readyAllDecDecorations', schCategoryColumnWidth),
    orderAndUnitPair('Ready All Decs', 'readyAllDecOrders', 'readyAllDecUnits', 'readyAllDecDecorations', schCategoryColumnWidth),
    orderAndUnitPair('Ready No Decs', 'readyNoDecOrders', 'readyNoDecUnits', 'readyNoDecDecorations', schCategoryColumnWidth),
    orderAndUnitPair('Express Production', 'expressProductionOrders', 'expressProductionUnits', 'expressProductionDecorations', schCategoryColumnWidth),
    orderAndUnitPair('Partner', 'partnerOrders', 'partnerUnits', 'partnerDecorations', schCategoryColumnWidth),
  ]), [shippingColumn, orderAndUnitPair]);

  const postDispatchColumnWidth = 208;
  const postDispatchColumns = useMemo(() => ([
    shippingColumn,
    orderAndUnitPair('Dispatched', 'dispatchedOrders', 'dispatchedUnits', 'dispatchedDecorations', postDispatchColumnWidth),
    orderAndUnitPair('Picked', 'pickedOrders', 'pickedUnits', 'pickedDecorations', postDispatchColumnWidth),
    orderAndUnitPair('Checked In', 'checkedInOrders', 'checkedInUnits', 'checkedInDecorations', postDispatchColumnWidth),
    orderAndUnitPair('Produced', 'producedOrders', 'producedUnits', 'producedDecorations', postDispatchColumnWidth),
    orderAndUnitPair('Shipped', 'shippedOrders', 'shippedUnits', 'shippedDecorations', postDispatchColumnWidth),
    {
      Header: 'Last Ship Date',
      id: '',
      minWidth: postDispatchColumnWidth,
      Cell: (cellProps: ColumnCellPropsType) => (
        cellProps.original.lastShipDate && parseDateNumeric(cellProps.original.lastShipDate)
      ),
    },
  ]), [shippingColumn, orderAndUnitPair]);

  const columns = activeTab === BatchWarehouseSchedulingTabs.MainScheduling
    ? mainColumns
    : activeTab === BatchWarehouseSchedulingTabs.SchedulingCategories
      ? schCategoryColumns
      : activeTab === BatchWarehouseSchedulingTabs.PostDispatch
        ? postDispatchColumns
        : [];

  const handleTabClick = (tab: BatchWarehouseSchedulingTabsType) => () => {
    setActiveTab(tab);
  };

  const handleGroupingType = useCallback((e) => {
    setGroupingType(e.target.value);
  }, []);
  const handleMinDate = useCallback((e) => {
    setMinDate(e.format('YYYY-MM-DD'));
  }, []);

  const handleMaxDate = useCallback((e) => {
    setMaxDate(e.format('YYYY-MM-DD'));
  }, []);

  const handleSearch = useCallback((e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key && e.key !== keyNameEnum.Enter) {
      return;
    }

    e.preventDefault();
    e.stopPropagation();

    const params = {
      filter: e.currentTarget.value,
      minDate,
      maxDate,
    };

    history.push(batchWarehouseSchedulingListUrlWithParams(params));
  }, [history, maxDate, minDate]);

  const handleClearSearch = useCallback(() => {
    void 0;
  }, []);

  useEffect(() => {
    headerMutator.setTitle('Scheduling 3.0 (beta)');
  }, [headerMutator]);

  const allPartners = useMemo(() => {
    if (!data?.groups) {
      return [];
    }

    return data.partners?.map((partner) => ({
      id: partner,
    })) ?? [];
  }, [data?.groups, data?.partners]);

  const filterPartners = useCallback((partners: string[]) => {
    setSelectedPartners(partners);
  }, []);

  const allVendors = useMemo(() => {
    if (!data?.groups) {
      return [];
    }

    return data.vendors?.map((vendor) => ({
      id: vendor,
    })) ?? [];
  }, [data?.groups, data?.vendors]);

  const filterVendors = useCallback((vendors: string[]) => {
    setSelectedVendors(vendors);
  }, []);

  return (
    <div className='container'>
      <div className='scheduling__container'>
        <div className='table-options w-100'>
          <div className='flex'>
            <Dropdown
              options={[
                <option key={1} value={BatchWarehouseGroupingDateTypeEnum.Shipping}>Shipping Date</option>,
                <option key={2} value={BatchWarehouseGroupingDateTypeEnum.Placed}>Placed Date</option>,
              ]}
              onChange={handleGroupingType}
              defaultValue={groupingType}
              classes={'mr-20'}
            />
            <div className='scheduling__datepicker'>
              <DateInput
                value={minDate}
                label={'Min Date'}
                onChange={handleMinDate}
              />
            </div>
            <div className='scheduling__datepicker'>
              <DateInput
                value={maxDate}
                label={'Max Date'}
                onChange={handleMaxDate}
              />
            </div>
          </div>
          <div className='flex'>
            <MultiSelectDropdown<PartnerDto, 'id'>
              objects={allPartners}
              itemText={'partners'}
              updateCallback={filterPartners}
              textKey={'id'}
              valueKey={'id'}
              size={dropdownSizeL}
              classNames={'margin-left'}
              selectedObjects={selectedPartners}
            />
            <MultiSelectDropdown<VendorDto, 'id'>
              objects={allVendors}
              itemText={'vendors'}
              updateCallback={filterVendors}
              textKey={'id'}
              valueKey={'id'}
              size={dropdownSizeL}
              classNames={'margin-left'}
              selectedObjects={selectedVendors}
            />
            <div className='margin-left'>
              <SearchFilter
                search={handleSearch}
                clearSearch={handleClearSearch}
                placeholder={'Search'}
              />
            </div>
          </div>
        </div>
      </div>
      <div className='scheduling__container'>
        <BatchWarehouseSchedulingViewOptions
          showOrders={showOrders}
          showUnits={showUnits}
          showDecorations={showDecorations}
          toggleOrders={toggleShowOrders}
          toggleUnits={toggleShowUnits}
          toggleDecorations={toggleShowDecorations}
        />
      </div>
      <ul className='tabs tabs--size-l'>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.MainScheduling ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.MainScheduling)}
        >
          Main Scheduling
        </li>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.SchedulingCategories ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.SchedulingCategories)}
        >
          Scheduling Categories
        </li>
        <li
          className={`tab-item ${activeTab === BatchWarehouseSchedulingTabs.PostDispatch ? 'is-active' : ''}`}
          onClick={handleTabClick(BatchWarehouseSchedulingTabs.PostDispatch)}
        >
          After Dispatch
        </li>
      </ul>
      <div className='scheduling__container'>
        <div className='w-100'>
          <div>
            <GroupingTable
              data={data?.groups ?? []}
              columns={columns}
              onFetchData={refetch}
              showPagination={false}
            />
          </div>
        </div>
      </div>
    </div>
  );
});

export default BatchWarehouseScheduling;
