import React, { useCallback } from 'react';
import {
  VoucherInstanceDto,
  VoucherInstanceStatusEnum,
} from '@api/financialServices/models';
import Icon from '@sharedComponents/Icons/Icon';
import Link from '@sharedComponents/Navigation/Link';
import { orderDetailsUrl } from '@constants/clientUrls/clientUrls';
import { Int32Int32DictionaryApiResponseResult } from '@api/fulfillment/models';

// Approximated width for one character in pixels (to calculate the instance code width)
const characterWidthInPixels = 10 as const;

const afterCodeSpaceInPixels = 10 as const;

interface OwnProps {
  instance: VoucherInstanceDto;
  orderNumbersForVoucherInstances: Int32Int32DictionaryApiResponseResult;
  onCopyVoucherCode: (instanceId: number, e: React.MouseEvent) => void;
  maxCodeLength: number;
  copyText: Record<string, string>;
}

type Props = OwnProps;

const VoucherInstance = React.memo<Props>(({
  instance,
  orderNumbersForVoucherInstances,
  onCopyVoucherCode,
  maxCodeLength,
  copyText,
}) => {
  const onCopy = useCallback((e) => {
    onCopyVoucherCode(instance.id!, e);
  }, [
    onCopyVoucherCode,
    instance,
  ]);

  const orderNumber = orderNumbersForVoucherInstances?.[instance.id!];

  return (
    <div
      key={instance.id!}
      className='voucherOrders__details__list__instance-row align__center justify__space-between'
    >
      <div className='w-30'>
        {`${instance.firstName} ${instance.lastName} `}
        <span>
          ({instance.email})
        </span>
      </div>
      <div className='align__center justify__space-between'>
        <div
          id={`codeToCopy-${instance.id}`}
          style={{
            width: `${maxCodeLength * characterWidthInPixels + afterCodeSpaceInPixels}px`,
          }}
        >
          {instance.code}
        </div>
        <div className='voucherOrders__details__list__instance-row__copy-icon align__center'>
          <Icon
            materialIcon={'content_copy'}
            onClick={onCopy}
            classes={'ml-10 cursor-pointer'}
          />
          <span className='copy-text'>{copyText[instance.id!]}</span>
        </div>
      </div>
      {
        instance.cancellationId && (
          <div
            className={'w-20 justify__end voucherOrders__details__list__instance-row__status--canceled'}
          >
            Canceled
          </div>
        )
      }
      {
        !instance.cancellationId && (
          <div
            className={`w-20 justify__end voucherOrders__details__list__instance-row__status--${instance.status === VoucherInstanceStatusEnum.Redeemed
              ? `${VoucherInstanceStatusEnum.Redeemed.toLowerCase()}`
              : VoucherInstanceStatusEnum.Pending.toLowerCase()}`
            }
          >
            {
              orderNumber
                ? (
                  <Link
                    url={orderDetailsUrl(orderNumber)}
                    text={`${instance.status} (O${orderNumber})`}
                    openInNewTab={true}
                  />
                )
                : <div>{instance.status}</div>
            }
          </div>
        )
      }
    </div>
  );
});

export default VoucherInstance;
