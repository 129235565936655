import React, { useState } from 'react';
import { Box } from '@material-ui/core';
import DropZoneWrapper from './DropZoneWrapper';
import Icon from '@sharedComponents/Icons/Icon';

const isValidUrl = (url) => {
  const pattern = new RegExp('^(https?:\\/\\/)?' // protocol
    + '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' // domain name
    + '((\\d{1,3}\\.){3}\\d{1,3}))' // OR ip (v4) address
    + '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' // port and path
    + '(\\?[;&a-z\\d%_.~+=-]*)?' // query string
    + '(\\#[-a-z\\d_]*)?$', 'i'); // fragment locator

  return !!pattern.test(url);
};

const getLast20Chars = (str) => {
  if (!str) {
    return str;
  }
  const lastSlashIndex = str.lastIndexOf('/');
  if (lastSlashIndex !== -1) {
    str = str.slice(lastSlashIndex + 1);
  }

  if (str.length > 20) {
    return str.slice(0, 10) + '...' + str.slice(-10);
  }

  return str;
};

const ImageDropzone = ({
  imageUrl, message, changeImage, acceptedFileTypes, size, showThumbnail = true,
}) => {
  const [
    loading,
    setLoading,
  ] = useState(false);

  const onDrop = (acceptedFiles, rejectedFiles) => {
    const reader = new FileReader();
    const file = acceptedFiles[0];

    let dropMessage;
    setLoading(true);

    if (rejectedFiles.length > 0) {
      dropMessage = {
        type: 'reject',
        body: `${acceptedFileTypes} files required.`,
      };
      changeImage(null, dropMessage, null);
      setLoading(false);
    } else {
      reader.onloadend = () => {
        const { result } = reader;
        dropMessage = {
          body: `File ${acceptedFiles[0].name} has been attached.`,
          type: 'default',
        };
        changeImage(result, dropMessage, acceptedFiles[0], size);
        setLoading(false);
      };

      reader.readAsDataURL(file);
    }
  };

  const dropzoneContent = loading
    ? (
      <label className='button'>
        <Icon
          fontAwesomeIcon={'spinner'}
          classes={'fa-spin'}
        />
        Uploading...
      </label>
    )
    : <label className={`button message-${message.type}`}>{message.body}</label>;

  const isUrl = isValidUrl(imageUrl);
  const shortenedText = getLast20Chars(imageUrl);

  return (
    <Box>
      <Box
        border='solid'
        style={{ borderWidth: '1px' }}
        width='200px'

      >
        {imageUrl &&
          <Box
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            {showThumbnail &&
              <img
                src={imageUrl}
                style={{
                  width: '200px',
                  height: '200px',
                }}
              />
            }
            {!showThumbnail &&
              <div>
                {isUrl ? <a href={imageUrl} target='_blank' rel='noreferrer'>{shortenedText}</a> : shortenedText}
              </div>
            }
          </Box>

        }
        {!imageUrl &&
          <Box
            display='flex'
            height='100%'
            alignItems='center'
            justifyContent='center'
          >
            <DropZoneWrapper
              onDrop={onDrop}
              multiple={false}
              className={'block-drop-zone'}
              accept={acceptedFileTypes ?? '.svg'}
            >
              {dropzoneContent}
            </DropZoneWrapper>
          </Box>
        }
      </Box>
    </Box>
  );
};

export default ImageDropzone;
