import React, { useCallback } from 'react';
import { QueryObserverResult } from 'react-query';
import {
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed,
  usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed,
} from '@api/fulfillment/production-assembly-new';
import {
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import MaterialTooltip from '@sharedComponents/Tooltips/MaterialTooltip';
import CheckButton from '@sharedComponents/Inputs/CheckButton';
import { DecorationMethodEnum } from '@api/productCatalog/models';
import {
  checkInNotCheckedStatuses,
  pretreatCheckedStatuses,
  pretreatEnabledStatuses,
  waterproofedCheckedStatuses,
  waterproofedEnabledStatuses,
} from '@constants/production';

interface OwnProps {
  decorationMethod: keyof typeof DecorationMethodEnum;
  item: ProductionAssemblyItemGroupDto;
  isAssemblyDisabled?: boolean;
  orderNumber: number;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
}

type Props = OwnProps;

const ReviewItemActions = React.memo<Props>(({
  decorationMethod,
  item,
  isAssemblyDisabled,
  orderNumber,
  refreshOrderItemGroups,
  refreshOrder,
}) => {
  const { mutateAsync: markOrderItemPreTreated }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkpretreated();

  const { mutateAsync: unmarkOrderItemPreTreated }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkpretreated();

  const { mutateAsync: markOrderItemWaterproofed }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsmarkwaterproofed();

  const { mutateAsync: unMarkOrderItemWaterproofed }
    = usePutHomefieldApiProductionassemblynewOrdersOrderNumberOrderitemsunmarkwaterproofed();

  const selectWaterproofCheckedButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderItemIds: item.orderItemIds!,
      orderNumber,
    };

    try {
      if (itemStatus === orderItemStatusEnum.ProductionCompleted) {
        await markOrderItemWaterproofed({
          orderNumber,
          data: model,
        });
        refreshOrderItemGroups();
        refreshOrder();
      } else if (itemStatus === orderItemStatusEnum.Waterproofed) {
        await unMarkOrderItemWaterproofed({
          orderNumber,
          data: model,
        });
        refreshOrderItemGroups();
        refreshOrder();
      }
    } catch (err) { }
  }, [
    item,
    orderNumber,
    refreshOrderItemGroups,
    refreshOrder,
    markOrderItemWaterproofed,
    unMarkOrderItemWaterproofed,
  ]);

  const selectPreTreatedButton = useCallback(async () => {
    const itemStatus = item.status?.status;
    const model = {
      orderNumber,
      orderItemIds: item.orderItemIds!,
    };

    try {
      if (itemStatus === orderItemStatusEnum.CheckedIn || itemStatus === orderItemStatusEnum.Assembled) {
        await markOrderItemPreTreated({
          orderNumber,
          data: model,
        });
      } else if (itemStatus === orderItemStatusEnum.PreTreated) {
        await unmarkOrderItemPreTreated({
          orderNumber,
          data: model,
        });
      }
    } catch (err) { console.error(err); }

    refreshOrderItemGroups();
    refreshOrder();
  }, [
    item,
    orderNumber,
    refreshOrderItemGroups,
    refreshOrder,
    markOrderItemPreTreated,
    unmarkOrderItemPreTreated,
  ]);

  if (!item) return null;

  const itemStatus = item.status?.status;

  const isDtg = decorationMethod === DecorationMethodEnum.DTG;

  const checkInChecked = !checkInNotCheckedStatuses.includes(itemStatus as typeof checkInNotCheckedStatuses[number]);

  const pretreatChecked = checkInChecked
    && !pretreatCheckedStatuses.includes(itemStatus as typeof pretreatCheckedStatuses[number]);

  const pretreatDisabled = isAssemblyDisabled
    || !pretreatEnabledStatuses.includes(itemStatus as typeof pretreatEnabledStatuses[number]);

  const waterproofedChecked
    = waterproofedCheckedStatuses.includes(itemStatus as typeof waterproofedCheckedStatuses[number]);

  const waterproofedDisabled = isAssemblyDisabled
    || !waterproofedEnabledStatuses.includes(itemStatus as typeof waterproofedEnabledStatuses[number]);

  return (
    <div className='actions mr-30'>
      {
        isDtg &&
        <MaterialTooltip
          tooltipText={
            (itemStatus === orderItemStatusEnum.PreTreated
              || itemStatus !== orderItemStatusEnum.Assembled)
              ? 'Pre-Treated' : ''
          }
          placement={'right'}
        >
          <CheckButton
            disabled={pretreatDisabled}
            text={'Pre-Treated'}
            checked={pretreatChecked}
            onClick={selectPreTreatedButton}
          />
        </MaterialTooltip>
      }
      {
        item.waterproofStepRequired &&
        <MaterialTooltip
          tooltipText={itemStatus !== orderItemStatusEnum.ProductionCompleted ? 'Waterproofed' : ''}
          placement={'right'}
        >
          <CheckButton
            disabled={waterproofedDisabled}
            text={'Waterproofed'}
            checked={waterproofedChecked}
            onClick={selectWaterproofCheckedButton}
          />
        </MaterialTooltip>
      }
    </div>
  );
});

export default ReviewItemActions;
