import {
  cancelReasonEnum,
  refundReasonEnum,
  cancelFullOrderReasonEnum,
  refundItemReasonEnum,
  cancelItemReasonEnum,
} from '@constants/enums/refundEnums';

// -- ENVIRONMENT VARIABLES --
export const s3Bucket = process.env.REACT_APP_S3_BUCKET!;
export const storefront = process.env.REACT_APP_STOREFRONT_ROOT_URL!;
export const teamOrganizerTemplateFile = process.env.REACT_APP_TEAM_ORGANIZER_TEMPLATE_URL!;

/*
 * -- API / AWS / MISC URL ROOTS --
 * for internal urls that lack a url stem
 */
export const imgURLRoot = 'https://s3.amazonaws.com/teamlocker-dev' as const;
export const newImgURLRoot = 'https://teamlocker.s3.amazonaws.com/uploads/' as const;
export const imgURLRootStem = 'https://teamlocker.s3.amazonaws.com' as const;
export const lockerMgrS3Logos = `${s3Bucket}/uploads` as const;
export const lockerMgrS3GridFiles = `${s3Bucket}/grids` as const;
export const lockerMgrS3Products = `${s3Bucket}/products` as const;
export const homefieldAssets = `${imgURLRootStem}/homefield_assets` as const;

export const productionGuideUrl = `${homefieldAssets}/ProductionGuide.pdf` as const;
export const fedexUrl = (trackingNumber: string): string => `https://www.fedex.com/apps/fedextrack/?action=track&trackingnumber=${trackingNumber}&cntry_code=us&locale=en_US`;
export const upsUrl = (trackingNumber: string): string => `https://wwwapps.ups.com/tracking/tracking.cgi?tracknum=${trackingNumber}`;
export const uspsUrl = (trackingNumber: string): string => `https://tools.usps.com/go/TrackConfirmAction_input?qtc_tLabels1=${trackingNumber}`;

export const DELIVERY_CARRIER_OPTIONS = {
  UPS: 'UPS',
  USPS: 'USPS',
  FedEx: 'FedEx',
  ThirdPartyManager: 'ThirdPartyManager',
} as const;

export type DELIVERY_CARRIER_OPTIONS_TYPE = typeof DELIVERY_CARRIER_OPTIONS[keyof typeof DELIVERY_CARRIER_OPTIONS];

export const getCarrierOptionByTrackingNumber = (trackingNumber: string): DELIVERY_CARRIER_OPTIONS_TYPE | null => {
  if (!trackingNumber) return null;
  if (trackingNumber.match(/1Z[A-Z0-9]{16}/)) return DELIVERY_CARRIER_OPTIONS.UPS;
  if (trackingNumber.match(/^E\D{1}\d{9}\D{2}$|^9\d{15,21}$/)) return DELIVERY_CARRIER_OPTIONS.USPS;
  if (trackingNumber.match(/^[0-9]{12,14}$/)) return DELIVERY_CARRIER_OPTIONS.FedEx;
  if (trackingNumber.match(/^https?:\/\//)) return DELIVERY_CARRIER_OPTIONS.ThirdPartyManager;

  return null;
};

export const trackingNumberUrl = (trackingNumber: string): string | null => {
  switch (getCarrierOptionByTrackingNumber(trackingNumber)) {
    case DELIVERY_CARRIER_OPTIONS.UPS:
      return upsUrl(trackingNumber);
    case DELIVERY_CARRIER_OPTIONS.USPS:
      return uspsUrl(trackingNumber);
    case DELIVERY_CARRIER_OPTIONS.FedEx:
      return fedexUrl(trackingNumber);
    case DELIVERY_CARRIER_OPTIONS.ThirdPartyManager:
      return trackingNumber;
    default:
      return null;
  }
};

export const trackingNumberLabel = (trackingNumber: string): string => {
  switch (getCarrierOptionByTrackingNumber(trackingNumber)) {
    case DELIVERY_CARRIER_OPTIONS.UPS:
      return `UPS ${trackingNumber}`;
    case DELIVERY_CARRIER_OPTIONS.USPS:
      return `USPS ${trackingNumber}`;
    case DELIVERY_CARRIER_OPTIONS.FedEx:
      return `FedEx ${trackingNumber}`;
    case DELIVERY_CARRIER_OPTIONS.ThirdPartyManager: {
      try {
        const url = new URL(trackingNumber);

        return `Link to ${url.hostname}`;
      } catch {
        return trackingNumber;
      }
    }
    default:
      return trackingNumber;
  }
};

/* #
 * -----------------
 * -- ENUMERABLE / MAPPINGS --
 */

// 0 indexed
export const artworkTaskStatusCodes = [
  'Unassigned',
  'In Progress',
  'Ready for Production',
  'In QC',
  'Rework',
  'In Production',
] as const;

export const translateCorrection = {
  'reachOutNeeded': 'Reach Out Needed',
  'vectorization': 'Vectorization',
  'backgroundRemoval': 'Background Removal',
  'gradient': 'Gradient',
} as const;

export const prepressIndicators = {
  'Undefined': '',
  'BlueCircle': 'Blue Circle',
  'RedCircle': 'Red Circle',
  'GreenCircle': 'Green Circle',
  'YellowCircle': 'Yellow Circle',
  'BlueSquare': 'Blue Square',
  'RedSquare': 'Red Square',
  'GreenSquare': 'Green Square',
  'YellowSquare': 'Yellow Square',
  'BlueTriangle': 'Blue Triangle',
  'RedTriangle': 'Red Triangle',
  'GreenTriangle': 'Green Triangle',
  'YellowTriangle': 'Yellow Triangle',
  'BlueMinus': 'Blue Minus',
  'RedMinus': 'Red Minus',
  'GreenMinus': 'Green Minus',
  'YellowMinus': 'Yellow Minus',
  'BluePlus': 'Blue Plus',
  'RedPlus': 'Red Plus',
  'GreenPlus': 'Green Plus',
  'YellowPlus': 'Yellow Plus',
} as const;

export const rushReasons = [
  {
    id: 1,
    reason: 'VIP',
  },
  {
    id: 2,
    reason: 'In Hands-Event',
  },
  {
    id: 3,
    reason: 'Salesrep',
  },
] as const;

export const cancelReasons = [
  {
    id: 1,
    reason: cancelReasonEnum.DuplicateOrder,
  },
  {
    id: 2,
    reason: cancelReasonEnum.Other,
  },
] as const;

export const refundReasons = [
  {
    id: 1,
    reason: refundReasonEnum.LateOrder,
  },
  {
    id: 2,
    reason: refundReasonEnum.HassleFreeReturn,
  },
  {
    id: 3,
    reason: refundReasonEnum.Other,
  },
] as const;

export const cancelFullOrderReasons = [
  {
    id: 4,
    reason: cancelFullOrderReasonEnum.AddingItems,
  },
  {
    id: 5,
    reason: cancelFullOrderReasonEnum.ChangingItems,
  },
  {
    id: 6,
    reason: cancelFullOrderReasonEnum.ChangingOrAddingPersonalization,
  },
  {
    id: 7,
    reason: cancelFullOrderReasonEnum.TurnaroundTime,
  },
  {
    id: 8,
    reason: cancelFullOrderReasonEnum.StockOut,
  },
  {
    id: 9,
    reason: cancelFullOrderReasonEnum.CustomerError,
  },
  {
    id: 10,
    reason: cancelFullOrderReasonEnum.WrongStore,
  },
  {
    id: 11,
    reason: cancelFullOrderReasonEnum.WrongItems,
  },
  {
    id: 12,
    reason: cancelFullOrderReasonEnum.DiscountNotApplied,
  },
  {
    id: 13,
    reason: cancelFullOrderReasonEnum.LogoAdjustments,
  },
  {
    id: 14,
    reason: cancelFullOrderReasonEnum.LogoCantBeProduced,
  },
  {
    id: 15,
    reason: cancelFullOrderReasonEnum.CustomerRequest,
  },
  {
    id: 16,
    reason: cancelFullOrderReasonEnum.NoLongerWithOrg,
  },
] as const;

export const refundItemReasons = [
  {
    id: 1,
    reason: refundItemReasonEnum.LateOrder,
  },
  {
    id: 2,
    reason: refundItemReasonEnum.HassleFreeReturn,
  },
  {
    id: 3,
    reason: refundItemReasonEnum.ItemDiscontinued,
  },
  {
    id: 4,
    reason: refundItemReasonEnum.Other,
  },
] as const;

export const cancelItemReasons = [
  {
    id: 5,
    reason: cancelItemReasonEnum.TurnaroundTime,
  },
  {
    id: 6,
    reason: cancelItemReasonEnum.StockOut,
  },
  {
    id: 7,
    reason: cancelItemReasonEnum.CustomerError,
  },
  {
    id: 8,
    reason: cancelItemReasonEnum.WrongStore,
  },
  {
    id: 9,
    reason: cancelItemReasonEnum.WrongItems,
  },
  {
    id: 10,
    reason: cancelItemReasonEnum.LogoAdjustments,
  },
  {
    id: 11,
    reason: cancelItemReasonEnum.LogoCantBeProduced,
  },
  {
    id: 12,
    reason: cancelItemReasonEnum.CustomerRequest,
  },
] as const;

export const decorationLocations = [
  {
    key: 1,
    value: 'BG',
    name: 'BG',
  },
  {
    key: 2,
    value: 'BN',
    name: 'BN',
  },
  {
    key: 3,
    value: 'FF',
    name: 'FF',
  },
  {
    key: 4,
    value: 'HD',
    name: 'HD',
  },
  {
    key: 5,
    value: 'LC',
    name: 'LC',
  },
  {
    key: 6,
    value: 'LL',
    name: 'LL',
  },
  {
    key: 7,
    value: 'RC',
    name: 'RC',
  },
  {
    key: 8,
    value: 'RL',
    name: 'RL',
  },
  {
    key: 9,
    value: 'CC',
    name: 'CC',
  },
  {
    key: 10,
    value: 'SL',
    name: 'SL',
  },
  {
    key: 11,
    value: 'VS',
    name: 'VS',
  },
  {
    key: 12,
    value: 'FB',
    name: 'FB',
  },

  {
    key: 13,
    value: 'SS_FF',
    name: 'SS_FF',
  },
  {
    key: 14,
    value: 'BL_LC',
    name: 'BL_LC',
  },
  {
    key: 15,
    value: 'BL_FF',
    name: 'BL_FF',
  },
  {
    key: 16,
    value: 'LB',
    name: 'LB',
  },

  {
    key: 17,
    value: 'FRONT',
    name: 'FRONT',
  },
  {
    key: 18,
    value: 'FRONTHD',
    name: 'FRONTHD',
  },
  {
    key: 19,
    value: 'BCKHD',
    name: 'BCKHD',
  },
  {
    key: 20,
    value: 'RHIP',
    name: 'RHIP',
  },
  {
    key: 21,
    value: 'TOP',
    name: 'TOP',
  },
  {
    key: 22,
    value: 'BOTTOM',
    name: 'BOTTOM',
  },
  {
    key: 23,
    value: 'SIDE',
    name: 'SIDE',
  },
  {
    key: 24,
    value: 'POCKET',
    name: 'POCKET',
  },
  {
    key: 25,
    value: 'SLEEVE',
    name: 'SLEEVE',
  },
  {
    key: 26,
    value: 'LHIP',
    name: 'LHIP',
  },
  {
    key: 27,
    value: 'MIDHD',
    name: 'MIDHD',
  },
  {
    key: 28,
    value: 'BGSM',
    name: 'BGSM',
  },
  {
    key: 29,
    value: 'UPBCK',
    name: 'UPBCK',
  },
  {
    key: 30,
    value: 'LWRBCK',
    name: 'LWRBCK',
  },
  {
    key: 31,
    value: 'UF',
    name: 'UF',
  },
  {
    key: 32,
    value: 'RSLEEVE',
    name: 'RSLEEVE',
  },
  {
    key: 33,
    value: 'LSLEEVE',
    name: 'LSLEEVE',
  },
] as const;

export const decorationMethods = [
  {
    key: 0,
    value: '',
    name: 'All decorations',
  },
  {
    key: 1,
    value: 'HAG',
    name: 'HAG',
  },
  {
    key: 2,
    value: 'EMB',
    name: 'EMB',
  },
  {
    key: 3,
    value: 'SUB',
    name: 'SUB',
  },
  {
    key: 4,
    value: 'DTG',
    name: 'DTG',
  },
  {
    key: 5,
    value: 'DIP',
    name: 'DIP',
  },
] as const;

export const schedulingDecorationMethods = [
  {
    key: 0,
    value: '',
    name: 'Any decoration',
  },
  {
    key: 1,
    value: 'Undefined',
    name: 'No decoration',
  },
  {
    key: 2,
    value: 'DTG',
    name: 'DTG Only',
  },
  {
    key: 3,
    value: 'EMB',
    name: 'EMB Only',
  },
  {
    key: 4,
    value: 'HAG',
    name: 'HAG Only',
  },
  {
    key: 5,
    value: 'DTG,EMB',
    name: 'DTG & EMB',
  },
  {
    key: 6,
    value: 'DTG,HAG',
    name: 'DTG & HAG',
  },
  {
    key: 7,
    value: 'EMB,HAG',
    name: 'EMB & HAG',
  },
  {
    key: 8,
    value: 'DTG,EMB,HAG',
    name: 'DTG & EMB & HAG',
  },
] as const;

export const inventoryStatuses = [
  {
    key: 0,
    value: '',
    name: 'All inventory statuses',
  },
  {
    key: 1,
    value: 'Available',
    name: 'Available',
  },
  {
    key: 2,
    value: 'Partial',
    name: 'Partial',
  },
  {
    key: 3,
    value: 'NoInventory',
    name: 'No Inventory',
  },
] as const;

export const markupValues = [
  {
    key: 0,
    value: 0,
  },
  {
    key: 1,
    value: 5,
  },
  {
    key: 2,
    value: 10,
  },
  {
    key: 3,
    value: 15,
  },
  {
    key: 4,
    value: 20,
  },
] as const;

export const printDataDefaultSorts = {
  'PrintReady': 'shipDate',
  'Done': 'statusChangedOn',
} as const;

export const discountTypes = [
  {
    key: 0,
    name: 'Dollar amount',
    unitOfMeasure: '$',
  },
  {
    key: 1,
    name: 'Percentage',
    unitOfMeasure: '%',
  },
] as const;

export const printQueueGroupByOptions = [
  {
    key: 1,
    value: 'orderNumber',
    name: 'Group by Order Number',
    groupTitle: '',
    keyPrefix: 'O',
  },
  {
    key: 2,
    value: 'colorGroup',
    name: 'Group by Garment Color',
    groupTitle: 'Color',
    keyPrefix: '',
  },
  {
    key: 3,
    value: 'categoryName',
    name: 'Group by Garment Type',
    groupTitle: 'Garment Type',
    keyPrefix: '',
  },
] as const;

// eslint-disable-next-line
export const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
export const alphabetsRegex = /[a-zA-Z]/g;

export { default as featureFlags }
  from './featureFlags';

export const SYC = 'SYC';
export const DEV = 'DEV';

export type DecorationMethodType =
  | 'Undefined'
  | 'DTG'
  | 'HAG'
  | 'EMB'
  | 'DIP'
  | 'SUB';

export const decorationMethodDisplayNames: Record<DecorationMethodType, { short: string; long: string; }> = {
  'Undefined': {
    short: 'Undecorated',
    long: 'Undecorated',
  },
  'DTG': {
    short: 'DTG',
    long: 'Direct To Garment',
  },
  'HAG': {
    short: 'HAG',
    long: 'Heat Applied Graphics',
  },
  'EMB': {
    short: 'EMB',
    long: 'Embroidery',
  },
  'DIP': {
    short: 'DIP',
    long: 'Digital Inkjet Printing',
  },
  'SUB': {
    short: 'SUB',
    long: 'Sublimated',
  },
};

// Decoration ids can be prefixed with the order number or just a number
export const decorationIdRegex = /^(O(?<orderNumber>[\d]+)-)?(?<decorationId>\d+)$/i;
