/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useMemo,
} from 'react';
import {
  connect,
  ConnectedProps,
} from 'react-redux';
import { QueryObserverResult } from 'react-query';
import { reset } from 'redux-form';
import { Column } from 'react-table-6';
import {
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag,
  usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag,
} from '@api/fulfillment/production-assembly-new';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import {
  ProductionAssemblyItemGroupDto,
  DecorationFlagTypeEnum,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import {
  productionAssemblyUnFlagItemForm,
  productionAssemblyFlagItemForm,
} from '@constants/reduxForms';
import {
  Task,
  LogoTask,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import { FlaggingFormData } from '@models/forms/Production/FlaggingFormData';
import { materialSwal } from '@util/componentHelper';
import { getFlagReasons } from '@util/flagHelper';
import {
  getHashCodeForLogoTask,
  getHashCodeForPersonalizationTask,
} from '@util/mappingHelper';
import FlagCell from '@components/WeedingAndMasking/Cells/FlagCell';
import AttachedFiles from '@components/PrintRoom/PrintRoomTableContent/AttachedFiles';
import ImagePreviewModal from '@sharedComponents/Modal/ImagePreviewModal';
import Table from '@sharedComponents/Table/Table';
import LogoOrTextCell from '@sharedComponents/Production/Cells/LogoOrTextCell';
import FlaggingModal from '@sharedComponents/Production/Modals/FlaggingModal';
import UnflagItemModal from '@sharedComponents/Production/Modals/UnflagItemModal';
import DecoratedButton from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemsTasksContent/DecoratedButton';
import DecorationLocationAndSizeCell from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemsTasksContent/DecorationLocationAndSizeCell';
import Icon from '@sharedComponents/Icons/Icon';
import { reprintUnavailableMethodsArr } from '@constants/production';

const TasksTable = Table<Task>();

interface OwnProps {
  decorationMethod: keyof typeof DecorationMethodEnum;
  item: ProductionAssemblyItemGroupDto;
  logoTasks: LogoTask[];
  personalizationTasks: PersonalizationTask[];
  orderNumber: number;
  isAssemblyDisabled?: boolean;
  itemStatus: keyof typeof orderItemStatusEnum;
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
  itemBarcodes: {
    orderItemId: number;
    barcode: string | null;
  }[];
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
}

const mapDispatchToProps = {
  resetForm: reset,
};

const connector = connect(null, mapDispatchToProps);

type Props = OwnProps & ConnectedProps<typeof connector>;

const ReviewItemTasks = React.memo(({
  logoTasks,
  personalizationTasks,
  orderNumber,
  colorsDictionary,
  colors,
  resetForm,
  refreshOrderItemGroups,
  refreshOrder,
  item,
  decorationMethod,
  itemBarcodes,
  isAssemblyDisabled,
  itemStatus,
}: Props) => {
  const [
    logoImagePreviewIsOpen,
    setLogoImagePreviewIsOpen,
  ] = useState(false);
  const [
    logoImagePreviewImageUrl,
    setLogoImagePreviewImageUrl,
  ] = useState<Nullable<string>>(null);
  const [
    flaggingModalIsOpen,
    setFlaggingModalIsOpen,
  ] = useState(false);
  const [
    unflaggingModalIsOpen,
    setUnflaggingModalIsOpen,
  ] = useState(false);
  const [
    selectedTask,
    setSelectedTask,
  ] = useState<Nullable<Task>>(null);

  const { mutateAsync: markOrderItemDecorationDecorated }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsmarkasdecorated();

  const { mutateAsync: unmarkOrderItemDecorationDecorated }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsunmarkasdecorated();

  const { mutateAsync: flagOrderItemDecoration }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsflag();

  const { mutateAsync: unflagOrderItemDecoration }
    = usePutHomefieldApiProductionassemblynewOrderitemdecorationsunflag();

  const openLogoPreviewModal = useCallback((imageUrl: string) => {
    setLogoImagePreviewIsOpen(true);
    setLogoImagePreviewImageUrl(imageUrl);
  }, []);

  const closeLogoPreviewModal = useCallback(() => {
    setLogoImagePreviewIsOpen(false);
    setLogoImagePreviewImageUrl(null);
  }, []);

  const openFlaggingModal = useCallback((task: Task) => {
    setFlaggingModalIsOpen(true);
    setSelectedTask(task);
  }, []);

  const openUnflaggingModal = useCallback((task: Task) => {
    setUnflaggingModalIsOpen(true);
    setSelectedTask(task);
  }, []);

  const closeFlaggingModal = useCallback(() => {
    resetForm(productionAssemblyFlagItemForm);

    setFlaggingModalIsOpen(false);
    setSelectedTask(null);
  }, [resetForm]);

  const closeUnflaggingModal = useCallback(() => {
    resetForm(productionAssemblyUnFlagItemForm);

    setUnflaggingModalIsOpen(false);
    setSelectedTask(null);
  }, [resetForm]);

  const orderItemDecorationDecoratedOnClick = useCallback(async (task: Task) => {
    const taskStatus = task?.status;

    try {
      if ((decorationMethod === DecorationMethodEnum.HAG
        && taskStatus === orderItemStatusEnum.CheckedIn)
        || (decorationMethod === DecorationMethodEnum.EMB
          && taskStatus !== orderItemStatusEnum.Decorated)
        || (decorationMethod === DecorationMethodEnum.DTG
          && taskStatus !== orderItemStatusEnum.Decorated)) {
        await markOrderItemDecorationDecorated({
          data: {
            orderNumber,
            ids: (task.ids.length > 0 ? task.ids : [task.id!]),
          },
        });

        refreshOrderItemGroups();
        refreshOrder();
      } else {
        await unmarkOrderItemDecorationDecorated({
          data: {
            orderNumber,
            ids: (task.ids.length > 0 ? task.ids : [task.id!]),
          },
        });

        refreshOrderItemGroups();
        refreshOrder();
      }
    } catch (err) { console.error(err); }
  }, [
    orderNumber,
    refreshOrderItemGroups,
    refreshOrder,
    markOrderItemDecorationDecorated,
    unmarkOrderItemDecorationDecorated,
    decorationMethod,
  ]);

  const getFileNameDict = useCallback(() => {
    const fileNameDict: { [key: string]: string; } = {};
    const dtgFileName = (itemBarcodes
      ?.filter((ib) => !!ib.barcode)
      ?.map((ib) => ib.barcode) || [])
      .sort()
      .join('-');

    if (!dtgFileName) return fileNameDict;

    for (const logo of item.logos!) {
      if (decorationMethod === DecorationMethodEnum.DTG && logo.files?.length) {
        for (const file of logo.files) {
          fileNameDict[file] = `${dtgFileName}.png`;
        }
      }
    }

    return fileNameDict;
  }, [
    decorationMethod,
    item,
    itemBarcodes,
  ]);

  const getGroupedItemColumns = (): Array<Column<Task>> => [
    {
      expander: true,
      Header: '',
      width: 50,
      Expander: (cellProps) => {
        const task: Task = cellProps.original;

        if (task.ids.length <= 1) return null;

        return (
          <div>
            {
              cellProps.isExpanded
                ? (
                  <Icon
                    materialIcon={'arrow_drop_up'}
                    classes={'button'}
                  />
                )
                : (
                  <Icon
                    materialIcon={'arrow_drop_down'}
                    classes={'button'}
                  />
                )
            }
          </div>
        );
      },
    },
    {
      Header: 'Item',
      accessor: '',
      width: 300,
      Cell: (cellProps) => (
        <span>
          <LogoOrTextCell
            task={cellProps.value}
            openModal={openLogoPreviewModal}
            showThreadColorValue={decorationMethod === DecorationMethodEnum.EMB}
            colorsDictionary={colorsDictionary}
            colors={colors}
            decorationMethod={decorationMethod}
            regenerateButtonVisible={false}
          />
        </span>
      ),
    },
    {
      Header: 'Location & Size',
      accessor: '',
      minWidth: 150,
      Cell: (cellProps) => {
        const task: Task = cellProps.value;

        return (
          <DecorationLocationAndSizeCell task={task} />
        );
      },
    },
    {
      Header: 'File(s)',
      accessor: 'files',
      width: 200,
      Cell: (cellProps) => {
        if (decorationMethod === DecorationMethodEnum.HAG) return null;

        return (
          <AttachedFiles
            files={cellProps.value}
            fileNameDict={getFileNameDict()}
          />
        );
      },
    },
    {
      Header: '',
      accessor: '',
      width: 400,
      Cell: (cellProps) => {
        const task: Task = cellProps.value;

        return (
          <div className='flex justify__end'>
            <div className='align__center'>
              <div className='ml-15'>
                <DecoratedButton
                  task={task}
                  taskStatus={task.groupStatus!}
                  decorationMethod={decorationMethod}
                  itemStatus={itemStatus}
                  isAssemblyDisabled={isAssemblyDisabled}
                  orderItemDecorationDecoratedOnClick={orderItemDecorationDecoratedOnClick}
                />
              </div>
              <div className='ml-15'>
                {
                  (task.ids?.length === 1
                    && !(reprintUnavailableMethodsArr.includes(
                      decorationMethod!
                    )
                      && ((task as PersonalizationTask).text || (task as PersonalizationTask).number))) &&
                  <FlagCell
                    item={task}
                    openFlaggingModal={openFlaggingModal}
                    openUnflaggingModal={openUnflaggingModal}
                    flagInfo={task.flag}
                    disabled={isAssemblyDisabled || itemStatus === orderItemStatusEnum.QualityChecked}
                  />
                }
              </div>
            </div>
          </div>
        );
      },
    },
  ];

  const getArtworkItemColumns = useCallback((): Array<Column<Task>> => (
    [
      {
        Header: 'Item',
        accessor: '',
        width: 350,
        Cell: (cellProps) => (
          <div>
            <LogoOrTextCell
              task={cellProps.value}
              openModal={openLogoPreviewModal}
              showThreadColorValue={decorationMethod === DecorationMethodEnum.EMB}
              colorsDictionary={colorsDictionary}
              colors={colors}
              decorationMethod={decorationMethod}
              regenerateButtonVisible={true}
            />
          </div>
        ),
      },
      {
        Header: 'Location & Size',
        accessor: 'decorationLocation',
        width: 150,
      },
      {
        Header: 'File(s)',
        accessor: 'files',
        width: 250,
        Cell: (cellProps) => {
          if (decorationMethod === DecorationMethodEnum.HAG) return null;

          return (
            <AttachedFiles
              files={cellProps.value}
              fileNameDict={getFileNameDict()}
            />
          );
        },
      },
      {
        Header: '',
        accessor: '',
        width: 350,
        Cell: (cellProps) => {
          const task: Task = cellProps.value;

          return (
            <div className='align__center justify__end'>
              <DecoratedButton
                task={task}
                taskStatus={task.status as (keyof typeof orderItemStatusEnum)}
                decorationMethod={decorationMethod}
                itemStatus={itemStatus}
                isAssemblyDisabled={isAssemblyDisabled}
                orderItemDecorationDecoratedOnClick={orderItemDecorationDecoratedOnClick}
              />
              <div className='ml-15'>
                {
                  !(
                    reprintUnavailableMethodsArr.includes(decorationMethod)
                    && ((task as PersonalizationTask).text || (task as PersonalizationTask).number)
                  ) &&
                  <FlagCell
                    item={task}
                    openFlaggingModal={openFlaggingModal}
                    openUnflaggingModal={openUnflaggingModal}
                    flagInfo={task.flag}
                    disabled={isAssemblyDisabled || itemStatus === orderItemStatusEnum.QualityChecked}
                  />
                }
              </div>
            </div>
          );
        },
      },
    ]
  ), [
    isAssemblyDisabled,
    decorationMethod,
    getFileNameDict,
    itemStatus,
    openFlaggingModal,
    openUnflaggingModal,
    openLogoPreviewModal,
    orderItemDecorationDecoratedOnClick,
    colorsDictionary,
    colors,
  ]);

  const getTrProps = useCallback((state: any, rowInfo: any) => {
    if (!rowInfo) {
      return {};
    }

    const { status } = (rowInfo.original as Task);

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  }, []);

  const getArtworkItemsTrProps = useCallback((state: any, rowInfo: any) => {
    if (!rowInfo) {
      return {};
    }
    const { status } = (rowInfo.original as Task);

    return {
      className: `${status === orderItemStatusEnum.Decorated ? 'is-active--light' : ''}`,
    };
  }, []);

  const flagItem = useCallback(async (form: FlaggingFormData) => {
    const {
      reason,
      note,
      flagType,
    } = form;

    try {
      const res = await flagOrderItemDecoration({
        data: {
          orderNumber,
          reason,
          note,
          flagType,
          ids: (selectedTask!.ids.length > 0 ? selectedTask!.ids : [selectedTask!.id!]),
        },
      });

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        refreshOrderItemGroups();
        closeFlaggingModal();
      }
    } catch (err) { console.error(err); }
  }, [
    closeFlaggingModal,
    refreshOrderItemGroups,
    selectedTask,
    orderNumber,
    flagOrderItemDecoration,
  ]);

  const unFlagItem = useCallback(async () => {
    try {
      const res = await unflagOrderItemDecoration({
        data: {
          orderNumber,
          ids: (selectedTask!.ids.length > 0 ? selectedTask!.ids : [selectedTask!.id!]),
        },
      });

      if (res?.success) {
        materialSwal('Success', res.message, 'success');
        refreshOrderItemGroups();
        closeUnflaggingModal();
      }
    } catch (err) { console.error(err); }
  }, [
    closeUnflaggingModal,
    refreshOrderItemGroups,
    orderNumber,
    selectedTask,
    unflagOrderItemDecoration,
  ]);

  const flagType = selectedTask && reprintUnavailableMethodsArr.includes(decorationMethod)
    ? DecorationFlagTypeEnum.Rework
    : DecorationFlagTypeEnum.Reprint;

  const reason = (selectedTask && getFlagReasons(flagType, decorationMethod)[0].reason) ?? undefined;

  const [
    groupedTasks,
    groupedLogosByHash,
    groupedPersonalizationsByHash,
  ] = useMemo((
  ): [Task[], Record<string, LogoTask[]>, Record<string, PersonalizationTask[]>] => {
    const groupedLogos: Record<string, LogoTask[]> = {};
    const groupedPersonalizations: Record<string, PersonalizationTask[]> = {};
    const groupedTasksList: Task[] = [];

    for (const logo of logoTasks) {
      const hash = getHashCodeForLogoTask(logo);
      if (!groupedLogos[hash]) {
        groupedLogos[hash] = [];
      }

      groupedLogos[hash].push(logo);
    }

    for (const personalization of personalizationTasks) {
      const hash = getHashCodeForPersonalizationTask(personalization);
      if (!groupedPersonalizations[hash]) {
        groupedPersonalizations[hash] = [];
      }

      groupedPersonalizations[hash].push(personalization);
    }

    for (const hash of Object.keys(groupedLogos)) {
      const logos = groupedLogos[hash];
      const groupedLogo = { ...logos[0] };
      groupedLogo.ids = logos.map((logo) => logo.id!);

      const notDecoratedStatus = logos.find(
        (logo) => logo.status !== orderItemStatusEnum.Decorated
      )?.status as (keyof typeof orderItemStatusEnum);
      groupedLogo.groupStatus = notDecoratedStatus || orderItemStatusEnum.Decorated;

      groupedTasksList.push(groupedLogo);
    }

    for (const hash of Object.keys(groupedPersonalizations)) {
      const personalizations = groupedPersonalizations[hash];
      const groupedPersonalization = { ...personalizations[0] };
      groupedPersonalization.ids = personalizations.map((personalization) => personalization.id!);

      const notDecoratedStatus = personalizations.find(
        (personalization) => personalization.status !== orderItemStatusEnum.Decorated
      )?.status as (keyof typeof orderItemStatusEnum);
      groupedPersonalization.groupStatus = notDecoratedStatus || orderItemStatusEnum.Decorated;

      groupedTasksList.push(groupedPersonalization);
    }

    return [
      groupedTasksList,
      groupedLogos,
      groupedPersonalizations,
    ];
  }, [
    logoTasks,
    personalizationTasks,
  ]);

  const getSubComponent = useCallback((cell: any) => {
    const task: Task = cell.original;

    const isLogo: boolean = !!(task as LogoTask).logoId;
    const hash = isLogo ? getHashCodeForLogoTask(task) : getHashCodeForPersonalizationTask(task);
    const tasks = isLogo ? groupedLogosByHash[hash] : groupedPersonalizationsByHash[hash];

    return (
      <TasksTable
        columns={getArtworkItemColumns()}
        classNames={''}
        data={tasks}
        showPagination={false}
        getTrProps={getArtworkItemsTrProps}
        customProps={{ TheadComponent: () => null }}
      />
    );
  }, [
    getArtworkItemColumns,
    getArtworkItemsTrProps,
    groupedLogosByHash,
    groupedPersonalizationsByHash,
  ]);

  return (
    <>
      <ImagePreviewModal
        modalIsOpen={logoImagePreviewIsOpen}
        closeModal={closeLogoPreviewModal}
        imageUrl={logoImagePreviewImageUrl ?? undefined}
      />
      <FlaggingModal
        isOpen={flaggingModalIsOpen}
        closeModal={closeFlaggingModal}
        onSubmit={flagItem}
        task={selectedTask}
        orderNumber={orderNumber}
        colorsDictionary={colorsDictionary}
        decorationMethod={decorationMethod}
        initialValues={{
          flagType,
          reason,
        }}
      />
      <UnflagItemModal
        isOpen={unflaggingModalIsOpen}
        closeModal={closeUnflaggingModal}
        onSubmit={unFlagItem}
        task={selectedTask}
        orderNumber={orderNumber}
        colorsDictionary={colorsDictionary}
        initialValues={{
          flagType: selectedTask?.flag?.type,
          reason: selectedTask?.flag?.reason ?? undefined,
          note: selectedTask?.flag?.note ?? undefined,
        }}
      />
      <TasksTable
        columns={getGroupedItemColumns()}
        data={groupedTasks}
        getTrProps={getTrProps}
        showPagination={false}
        classNames={''}
        subComponent={getSubComponent}
        customProps={{ TheadComponent: () => null }}
      />
    </>
  );
});

export default connector(ReviewItemTasks);
