import React, {
  useCallback,
  useEffect,
  useState,
} from 'react';
import Modal from '@sharedComponents/Modal/Modal';
import ModalButtons from '@sharedComponents/Modal/ModalButtons';
import OrderManagementDetailsOrderItemListNew from './OrderManagementDetailsOrderItemListNew';
import { usePostHomefieldApiIntegrationSsapidatasync } from '@api/orders/integration';
import { materialSwal } from '@util/componentHelper';
import { LockerItemWithQuantity } from '@models/common/LockerItemWithQuantity';
import { LockerProductsCssLogoViewModel } from '@api/squadlockerServices/models';
import {
  Logo as LogoDto,
  QueriesVariationDto,
} from '@api/orders/models';
import { OrderItemDetailsDto } from '@api/fulfillment/models';
import { getQueryClientInstance } from '@util/queryClientHelper';

interface OwnProps {
  isOpen: boolean;
  replace: (form: any) => void;
  closeModal: () => void;
  lockerId: number;
  openReplaceItemBySkuModal:
  (orderItem: OrderItemDetailsDto,
    sku: string,
    selectedLogo: Nullable<LogoDto | LockerProductsCssLogoViewModel>,
    synced: boolean) => void;
  orderItem: OrderItemDetailsDto;
  getOrderItemProductBySku: (sku: string) => void;
  synced: boolean;
  setSynced: (synch: boolean) => void;
  setInitialBasePrice: (initialBasePrice: Nullable<number>) => void;
  orderExistsOnCore: boolean;
}

type Props = OwnProps;

const queryClient = getQueryClientInstance();

const OrderManagementDetailsReplaceItemModalNew = React.memo<Props>(({
  isOpen,
  closeModal,
  lockerId,
  openReplaceItemBySkuModal,
  orderItem,
  getOrderItemProductBySku,
  synced,
  setSynced,
  setInitialBasePrice,
  orderExistsOnCore,
}) => {
  const [
    selectedItem,
    setSelectedItem,
  ] = useState<Nullable<LockerItemWithQuantity | QueriesVariationDto>>(null);
  const [
    selectedSize,
    setSelectedSize,
  ] = useState<Nullable<{ sku: string; size: string; }>>(null);
  const [
    selectedLogo,
    setSelectedLogo,
  ] = useState<Nullable<LogoDto | LockerProductsCssLogoViewModel>>(null);
  const [
    isSynching,
    setIsSynching,
  ] = useState<boolean>(false);
  const [
    synchingError,
    setSychingError,
  ] = useState<boolean>(false);

  const { mutateAsync: syncLockersAsync } = usePostHomefieldApiIntegrationSsapidatasync(
    {
      request: { isBlockingRequest: false },
      mutation: {
        mutationKey: 'coreSync',
      },
    }
  );

  const syncLockersForNewOrder = useCallback(async (lockerNumber?: number) => {
    if (orderExistsOnCore) {
      const variables = {
        params: {
          organizationId: undefined,
          salesChannelId: lockerNumber ? [lockerNumber] : [],
        },
      };

      setIsSynching(true);

      const res = await syncLockersAsync(variables);

      if (res?.success) {
        setSynced(true);
        setIsSynching(false);
      } else {
        setSychingError(true);
        setIsSynching(false);
      }
    } else {
      setSynced(true);
    }
  }, [
    setSynced,
    syncLockersAsync,
    orderExistsOnCore,
  ]);

  useEffect(() => {
    if (isOpen && !synced) {
      if (!syncLockersForNewOrder) return;
      if (!lockerId) return;

      syncLockersForNewOrder(lockerId);
    }
  }, [
    isOpen,
    lockerId,
    syncLockersForNewOrder,
    synced,
  ]);

  const selectSize = useCallback((size: Nullable<{ sku: string; size: string; }>) => {
    setSelectedSize(size);
  }, []);

  const selectLogo = useCallback((logo: Nullable<LogoDto | LockerProductsCssLogoViewModel>) => {
    setSelectedLogo(logo);
  }, []);

  const selectItem = useCallback((item: Nullable<LockerItemWithQuantity> | Nullable<QueriesVariationDto>) => {
    setSelectedItem(item);
    setInitialBasePrice((item as QueriesVariationDto)?.item?.pricing?.basePrice ?? null);
  }, [setInitialBasePrice]);

  const modalClose = useCallback(() => {
    setSelectedSize(null);
    setSelectedLogo(null);
    setSelectedItem(null);
    setSynced(false);
    queryClient.cancelMutations();

    closeModal();
  }, [
    closeModal,
    setSynced,
  ]);

  const openNextStepModal = useCallback(() => {
    if (!isSynching) {
      modalClose();
      getOrderItemProductBySku(orderItem.sku!);
      openReplaceItemBySkuModal(orderItem, selectedSize?.sku ? selectedSize?.sku : '', selectedLogo, synced);
    }
  }, [
    getOrderItemProductBySku,
    isSynching,
    modalClose,
    openReplaceItemBySkuModal,
    orderItem,
    selectedLogo,
    selectedSize?.sku,
    synced,
  ]);

  return (
    <Modal
      isOpen={isOpen}
      closeModal={modalClose}
      modalSize={'xl'}
      title={'Replace Item'}
      buttons={(
        <ModalButtons
          onClose={modalClose}
          onConfirm={openNextStepModal}
          confirmBtnText={'Next'}
          confirmBtnDisabled={!selectedItem || !selectedSize}
        />
      )}
    >
      <>
        {
          <OrderManagementDetailsOrderItemListNew
            isOpen={isOpen}
            lockerId={lockerId}
            isOrderItemAdd={false}
            onItemSelected={selectItem}
            onLogoSelected={selectLogo}
            onSizeSelected={selectSize}
            synced={synced}
            synchingError={synchingError}
            orderExistsOnCore={orderExistsOnCore}
          />
        }
      </>
    </Modal>
  );
});

export default OrderManagementDetailsReplaceItemModalNew;
