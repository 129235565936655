export const rolesEnum = {
  Developer: 'developer',
  ProductionArtworkVendor: 'production_artwork_vendor',
  SlProductionArt: 'sl_production_art',
  SlGraphicServices: 'sl_graphic_services',
  SLProductionScheduler: 'sl_production_scheduler',
  SLCustomerExperience: 'sl_customer_experience',
  SLPrintRoom: 'sl_print_room',
  SLOrderLookup: 'sl_order_lookup',
  SLLockerManager: 'sl_locker_manager',
  SLSalesManager: 'sl_sales_manager',
  SLOrderAssembly: 'sl_order_assembly',
  SlProductionAssemblyHagOperator: 'sl_production_assembly_hag_operator',
  SlProductionAssemblyEmbOperator: 'sl_production_assembly_emb_operator',
  SlProductionAssemblyDtgOperator: 'sl_production_assembly_dtg_operator',
  SLOrderProduction: 'sl_order_production',
  SLQualityControl: 'sl_quality_control',
  SLAccountingAccess: 'sl_accounting_access',
  SLMerchandisingRep: 'sl_merchandising_rep',
  SLMerchandisingLite: 'sl_merchandising_lite',
  SLLockerManagerExternal: 'sl_locker_manager_external',
} as const;

export const errorSeverityEnum = {
  warning: 'warning',
  error: 'error',
} as const;

export const sampleImageColorsEnum = {
  Black: 'BLACK',
  White: 'WHITE',
} as const;

export const emptyImageTextEnum = {
  Front: 'FRONT',
  Back: 'BACK',
  Side: 'SIDE',
  LeftSide: 'LEFT SIDE',
  RightSide: 'RIGHT SIDE',
  Grid: 'GRID',
  NoImage: 'NO IMAGE',
} as const;

export const imageLocationsEnum = {
  Front: 'imageFront',
  Back: 'imageBack',
  Left: 'imageLeft',
  Right: 'imageRight',
} as const;

export const mappingMethodEnum = {
  id: 'id',
  sku: 'sku',
} as const;

export const flagTypeEnum = {
  Reprint: 'Reprint',
  Rework: 'Rework',
  WrongArtwork: 'WrongArtwork',
} as const;

export const filterTypes = {
  brands: 'brands',
  colors: 'colors',
  sizes: 'sizes',
  ages: 'ages',
  genders: 'genders',
  categories: 'categories',
  sports: 'sports',
  quantity: 'quantity',
  visibility: 'visibility',
  vendors: 'vendors',
  decorationMethods: 'decorationMethods',
  prdOnly: 'prdOnly',
} as const;

export const toggleButtonStateEnum = {
  ON: 'ON',
  OFF: 'OFF',
} as const;

export const sortDirectionEnum = {
  Asc: 'ascending',
  Desc: 'descending',
} as const;

export const sortDirectionShortEnum = {
  Asc: 'asc',
  Desc: 'desc',
} as const;

export const tabSizesEnum = {
  Medium: 'M',
  Large: 'L',
} as const;

export const keyNameEnum = {
  Enter: 'Enter',
  ArrowRight: 'ArrowRight',
  ArrowLeft: 'ArrowLeft',
} as const;

export const stripePaymentMethodEnum = {
  Klarna: 'klarna',
  Afterpay: 'afterpay_clearpay',
  Affirm: 'affirm',
  AmazonPay: 'amazon_pay',
  Card: 'card',
  Link: 'link',
} as const;
