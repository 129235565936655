/* eslint-disable @typescript-eslint/no-explicit-any */
import React, {
  useState,
  useCallback,
  useEffect,
  useMemo,
} from 'react';
import { QueryObserverResult } from 'react-query';
import LazyLoad from 'react-lazyload';
import { useGetHomefieldApiProductsSku } from '@api/productCatalog/products';
import { useGetHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId } from '@api/productCatalog/dtg-configuration';
import {
  ColorDto,
  DecorationMethodEnum,
} from '@api/productCatalog/models';
import {
  ProductionAssemblyItemGroupDto,
  ProductionAssemblyOrderHeaderDto,
} from '@api/fulfillment/models';
import { orderItemStatusEnum } from '@constants/enums/orderEnums';
import { StatusHistory } from '@models/Production/ProductionAssembly/StatusHistory';
import {
  LogoTask,
  PersonalizationTask,
} from '@models/Production/ProductionAssembly/Tasks';
import AddButton from '@sharedComponents/Display/AddButton';
import EditButton from '@sharedComponents/Display/EditButton';
import ReviewItemHistory from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemHistory';
import ReviewItemSettings from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemSettings';
import ReviewItemInfoTooltip from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemInfoTooltip';
import ReviewItemBarcodes from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemBarcodes';
import ReviewItemLabelsLegendDisplay from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemLabelsLegendDisplay';
import ReviewItemTasks from './ReviewItemContent/ReviewItemTasks';
import ReviewItemActions from './ReviewItemContent/ReviewItemActions';
import ReviewImageCarousel from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewImageCarousel';
import ReviewItemImage from '@sharedComponents/Production/Review/ReviewItems/ReviewItemContent/ReviewItemImage';
import {
  checkInNotCheckedStatuses,
  pretreatCheckedStatuses,
} from '@constants/production';
import { useFlags } from 'launchdarkly-react-client-sdk';

interface OwnProps {
  decorationMethod: keyof typeof DecorationMethodEnum;
  item: ProductionAssemblyItemGroupDto;
  orderNumber: number;
  order?: any;
  isAssemblyDisabled?: boolean;
  openItemInfoModal: (item: ProductionAssemblyItemGroupDto) => void;
  openAddBarcodesModal: (
    item: ProductionAssemblyItemGroupDto
  ) => void;
  openEditBarcodesModal: (
    item: ProductionAssemblyItemGroupDto
  ) => void;
  isStatusHistoryShown: boolean;
  getItemHistory: (itemIds: number[], lineItemsIds: number[]) => StatusHistory;
  logoDecorationLocations: any[];
  personalizationDecorationLocations: any[];
  decorationLocationPersonalizationSizesList: any[];
  decorationLocationArtworkSizesList: any[];
  colorsDictionary: Record<string, ColorDto>;
  colors: ColorDto[] | undefined;
  refreshOrderItemGroups: () => Promise<QueryObserverResult<ProductionAssemblyItemGroupDto[], unknown>>;
  refreshOrder: () => Promise<QueryObserverResult<ProductionAssemblyOrderHeaderDto, unknown>>;
  barcodeParam: Nullable<string>;
  itemSelected: boolean;
  barcodeSearchItemRef: React.RefObject<HTMLDivElement>;
  scrollToItem: () => void;
}

type Props = OwnProps;

const ReviewItem = React.memo(({
  item,
  barcodeParam,
  itemSelected,
  scrollToItem,
  decorationMethod,
  isAssemblyDisabled,
  orderNumber,
  isStatusHistoryShown,
  getItemHistory,
  colorsDictionary,
  colors,
  refreshOrderItemGroups,
  refreshOrder,
  barcodeSearchItemRef,
  openAddBarcodesModal,
  openEditBarcodesModal,
  openItemInfoModal,
  personalizationDecorationLocations,
  decorationLocationPersonalizationSizesList,
  logoDecorationLocations,
  decorationLocationArtworkSizesList,
}: Props) => {
  const [
    itemImage,
    setItemImage,
  ] = useState('');

  const {
    data: product,
    refetch: fetchProduct,
  } = useGetHomefieldApiProductsSku(item.sku!);

  const {
    data: dtgColoredStyleConfiguration,
    refetch: fetchDtgColoredStyleConfiguration,
  }
    = useGetHomefieldApiDtgconfigurationsColoredstyleconfigurationColoredStyleId(
      (product ?? {}).coloredStyleId!
    );

  useEffect(() => {
    if (!item) return;
    fetchProduct();
  }, [
    item,
    fetchProduct,
  ]);

  useEffect(() => {
    if (!product) return;
    fetchDtgColoredStyleConfiguration();
  }, [
    product,
    fetchDtgColoredStyleConfiguration,
  ]);

  const findImage = useCallback((
    selectedItem: ProductionAssemblyItemGroupDto
  ) => {
    let images: (string | undefined)[] = [];
    images.push.apply(images, [
      selectedItem.imageUrlFront!,
      selectedItem.imageUrlBack!,
      selectedItem.imageUrlLeft!,
      selectedItem.imageUrlRight!,
    ]);

    images = images.filter(Boolean);
    if (images.length > 0) {
      setItemImage(images[0]!);
    } else {
      setItemImage(product?.coloredStyle?.imageUrlFront || '');
    }
  }, [product]);

  useEffect(() => {
    if (item) {
      findImage(item);
      fetchProduct();
    }
  }, [
    findImage,
    item,
    fetchProduct,
  ]);

  useEffect(() => {
    if (barcodeParam && itemSelected) {
      scrollToItem();
    }
  }, [
    barcodeParam,
    itemSelected,
    scrollToItem,
  ]);

  const handleOpenItemInfoModal = useCallback(() => {
    openItemInfoModal({
      ...item,
      imageUrlFront: itemImage || item.imageUrlFront,
    });
  }, [
    openItemInfoModal,
    item,
    itemImage,
  ]);

  const getItemBarcodes = () => {
    const itemBarcodes: {
      orderItemId: number;
      barcode: string | null;
    }[] = [];

    for (const orderItemId of Object.keys(item.orderItemIdsWithBarcode!)) {
      const barcode: string | null = item.orderItemIdsWithBarcode![orderItemId];
      itemBarcodes.push({
        orderItemId: Number(orderItemId),
        barcode,
      });
    }

    return itemBarcodes;
  };

  const handleOpenAddBarcodesModal = useCallback(() => {
    openAddBarcodesModal(item);
  }, [
    openAddBarcodesModal,
    item,
  ]);

  const handleOpenEditBarcodesModal = useCallback(() => {
    openEditBarcodesModal(item);
  }, [
    openEditBarcodesModal,
    item,
  ]);

  const logoTasks = useMemo((): LogoTask[] => {
    const logos: LogoTask[] = [];

    for (const logo of item.logos!) {
      const logoDecorationLocation = logoDecorationLocations.find((l) => l.code === logo.decorationLocation);
      const logoDecorationSize = logoDecorationLocation && decorationLocationArtworkSizesList
        .find((s) => s.decorationLocationId === logoDecorationLocation.id
          && s.decorationMethod === decorationMethod);

      const updatedLogo: LogoTask = {
        ...logo,
        ids: [],
        decorationWidth: logo.decorationWidth || logoDecorationSize?.width,
        decorationHeight: logo.decorationHeight || logoDecorationSize?.height,
      };

      logos.push(updatedLogo);
    }

    return logos;
  }, [
    logoDecorationLocations,
    decorationLocationArtworkSizesList,
    item,
    decorationMethod,
  ]);

  const personalizationTasks = useMemo((): PersonalizationTask[] => {
    const personalizations: PersonalizationTask[] = [];

    for (const personalization of item.personalizations!) {
      const updatedPersonalization: PersonalizationTask = {
        ...personalization,
        ids: [],
      };

      if (personalizationDecorationLocations.length > 0) {
        const personalizationDecorationLocation = personalizationDecorationLocations
          .find((l) => l.code === personalization.decorationLocation);

        const personalizationDecorationSize = decorationLocationPersonalizationSizesList
          .find((s) => s.decorationLocationId === personalizationDecorationLocation?.id
            && s.decorationMethod === decorationMethod);

        updatedPersonalization.nameHeight = personalizationDecorationSize?.nameHeight;
        updatedPersonalization.numberHeight = personalizationDecorationSize?.numberHeight;

        personalizations.push(updatedPersonalization);
      }
    }

    return personalizations;
  }, [
    personalizationDecorationLocations,
    decorationLocationPersonalizationSizesList,
    item,
    decorationMethod,
  ]);

  const decorationIds: number[] = [
    ...item.logos!.map((logo) => logo.id!),
    ...item.personalizations!.map((personalization) => personalization.id!),
  ];

  const statusHistory: StatusHistory = getItemHistory(item.orderItemIds!, decorationIds);

  const dtgSetting = dtgColoredStyleConfiguration;

  const itemStatus = item.status?.status;
  const checkInChecked = !checkInNotCheckedStatuses.includes(itemStatus as typeof checkInNotCheckedStatuses[number]);

  const pretreatChecked = checkInChecked
    && !pretreatCheckedStatuses.includes(itemStatus as typeof pretreatCheckedStatuses[number]);

  const itemBarcodes = getItemBarcodes();
  const itemBarcodesNonEmpty = itemBarcodes.filter((ib) => !!ib.barcode) || [];

  const highlighted: boolean = !!barcodeParam && itemSelected;

  const { lineLevel1 } = useFlags();

  return (
    <div
      ref={(barcodeParam && itemSelected) ? barcodeSearchItemRef : undefined}
      className={`order-assembly order-assembly__item ${highlighted ? 'order-assembly__item--highlighted' : ''}`}
    >
      <LazyLoad
        height={400}
        once={true}
      >
        <div className='order-assembly__item--info container w-100'>
          {
            item.imageUrlFront === null
              ? (
                <ReviewItemImage
                  item={item}
                  itemImage={itemImage}
                  openItemInfoModal={handleOpenItemInfoModal}
                  showDecorationMethodLabel={true}
                />
              )
              : (
                <ReviewImageCarousel
                  item={item}
                  itemImage={itemImage}
                  handleOpenItemInfoModal={handleOpenItemInfoModal}
                  setItemImage={setItemImage}
                />
              )
          }
          <div className='order-assembly__item--info--details'>
            <div className='align__center justify__space-between w-100'>
              <div className='details'>
                <div className='details__product'>
                  <div className='details__product__title'>
                    <span>{item.sku}</span>
                    <ReviewItemInfoTooltip itemProduct={product} />
                    <div className='border' />
                    <span className='details__product__title--name'>
                      <span>{product?.coloredStyle?.style?.name}</span>
                      <ReviewItemLabelsLegendDisplay item={item} />
                    </span>
                    <div className='border' />
                    <span className='details__product__title--name'>Qty: {item.quantity}</span>
                  </div>

                  <div className='details__product--minor'>
                    {
                      (decorationMethod === DecorationMethodEnum.DTG || lineLevel1) &&
                      <>
                        <ReviewItemBarcodes itemBarcodes={itemBarcodesNonEmpty} />
                        {
                          itemBarcodesNonEmpty.length > 0
                            ? (
                              <EditButton
                                text={'Edit Barcode(s)'}
                                classes={'ml-0 align__center'}
                                onClick={handleOpenEditBarcodesModal}
                              />
                            )
                            : (
                              <AddButton
                                text={'Add Barcode(s)'}
                                classes={'align__center'}
                                onClick={handleOpenAddBarcodesModal}
                              />
                            )
                        }
                      </>
                    }
                  </div>

                </div>
              </div>
              <div>
                <ReviewItemActions
                  decorationMethod={decorationMethod}
                  item={item}
                  orderNumber={orderNumber}
                  isAssemblyDisabled={isAssemblyDisabled}
                  refreshOrderItemGroups={refreshOrderItemGroups}
                  refreshOrder={refreshOrder}
                />
              </div>
            </div>
            <ReviewItemTasks
              decorationMethod={decorationMethod}
              colorsDictionary={colorsDictionary}
              colors={colors}
              item={item}
              logoTasks={logoTasks}
              personalizationTasks={personalizationTasks}
              isAssemblyDisabled={isAssemblyDisabled}
              orderNumber={orderNumber}
              itemStatus={itemStatus as keyof typeof orderItemStatusEnum}
              itemBarcodes={itemBarcodes}
              refreshOrderItemGroups={refreshOrderItemGroups}
              refreshOrder={refreshOrder}
            />
            {
              dtgSetting &&
              <ReviewItemSettings
                dtgSetting={dtgSetting}
                checkedIn={checkInChecked}
                pretreated={pretreatChecked}
                statusHistoryOpened={isStatusHistoryShown}
              />
            }
            {
              isStatusHistoryShown &&
              <ReviewItemHistory
                item={item}
                statusHistory={statusHistory}
              />
            }
          </div>
        </div>
      </LazyLoad>
    </div>
  );
});

export default ReviewItem;
